import React, {Component} from 'react';
import {Map, Marker, Polyline, GoogleApiWrapper, InfoWindow, Polygon, Circle} from 'google-maps-react';
import api from './api';
import {paginationSwipe} from './paginationSwipe';
import {ToastContainer, ToastStore} from 'react-toasts';
import axios from 'axios';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import {Modals} from './Modals';
import './estilo.css';
import {Spinner, Button, Navbar, Nav, Form, Image,Carousel, Table, Container, Row, Col, ButtonGroup} from 'react-bootstrap';
import {GetPosicoesVeiculo} from './GetPosicoesVeiculo.js';
import {GetPosicoes} from './GetPosicoes';
import ReactCountdownClock  from 'react-countdown-clock';
import {  Button as ButtonFab, lightColors } from 'react-floating-action-button'

import {PainelGrid} from './PainelGrid';

// import {PolylineRender} from './PolylineRender';

import FilterSelect from './filterSelect'
import {RenderEventos} from './RenderEventos';
import {GetEventos} from './services/GetEventos';
import EventoCard from './EventoCard';
import {RenderTemperatura} from './RenderTemperatura';
import mapStyles from "./GoogleMapStyle.js";

import { Ring, Spinner as Spinner2, Ripple } from 'react-spinners-css';

import './edit.css';

var moment = require('moment');

const TIPO_CHAMADA_PLUSCLICK = 0;
const TIPO_CHAMADA_MARKERCLICK_VEICULO = 1;
const TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO = 2;
const TIPO_CHAMADA_RECARREGAR_CLICK = 3;



function loadImg() {
  return <img   alt="" src={require('./img/point_position4_3.png')} />;
}




const mapStyle = [
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dceafa'
      }
    ]
  },
]



// import rotateimg from './RotateIMG'

class MapaRastreio extends Component{

  constructor(props){
    super(props);
    this.state = {
        places: [
          {
            recebimento_id:1,
            placa: 'Casa',
            latitude: -27.092218,
            longitude: -52.612825,
            velocidade: 0
          },
          {
            recebimento_id:2,
            placa: 'Sesc',
            latitude: -27.103833,
            longitude: -52.626796,
            velocidade: 0
          }
        ],
    //----
      errorMessage: null,
      loggedInUser: null,
      posicoesList: [],

      list: [],
      posicao: {},

      filter_list: [],
      markerPlus: {},
      markerEvento_list: [],
      markerPosicao_list: [],
      markerAux_list: [],
      eventoClicado_list: [],
      evento: {},

    //-------
      lastLat: null,
      lastLong: null,
      latitudeDelta: null,
      longitudeDelta: null,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      persons: [],

      pessoa_id:this.props.pessoa_id,
      gerente: this.props.gerente,
      token:null,
      usuario_id: this.props.usuario_id,
      nome:null,
      grupo_id:null,
      showSheet:false,
      bottomSheet:false,
      open: false,
      placa:null,
      bsOverflowHeight:0,
      bsColor:'#00bcd4',
      zooom:6,
      center:null,
      arrow_collapse:require("./img/arrow_collapse_up.png"),
      variant_button:"success",
      coords:[],
      posicoesVeiculo:[],
      eventos_list: [],
      veiculoPosicao_selecionado: null,
      veiculo_selecionado_temperatura: false,
      carregarPolyline:false,
      carregarFlechas:false,
      // segundos:900,
      // segundos:600,

      segundos:300,  
      // segundos:30,  


      // segundos:200,
      // segundos:100,
      // segundos:60,
      // segundos:10,
      terminou_segundos:false,
      completions: 0,
      buttonClockTransp:"texto_transparente",
      backgroundBottomSheet:'',
      backgroundBottomSheet2:'',
      showingInfoWindow: false,
      showingInfoWindowTrecho: false,
      activeMarker: {},
      activeMarkerInfoViewEventos: {},
      selectedPlace: {},

      outlineLight : "outline-light",
      light : "light",
      tabHistoricoSel: false,
      tabTemperaturaSel: false,
      activeIndexCarousel: 0,
      splashScreenModalShow: false,
      marker_aux: null,
      infoWindowLocation: null,
      showingInfoWindow2: false,
      infoWindowEvento: false,
      carregandoMaisEventos: false,
      lineChartTemp: null,
      veiculo_id_selecionado:null,
      tipo_chamada:null,
      polylineColor:"#7441E7",
      // polylineColor_list:["#7441E7","#7847e6","#8155e6","#895fe8","#9068e8","#9772e8","#9c79e8","#a282e8","#a587e6"
      //                     ,"#a88ce6","#ae96e3","#b49fe3","#baa8e3","#c2b3e6","#c8bce6","#c9bfe3","#cec5e3","#d5cfe6","#dad5e8"],
      polylineColor_list:["#7441E7","#9068e8","#a587e6","#baa8e3","#cec5e3","#d5cfe6","#dad5e8"],
      indexPolylineColor:0,
      tema_mapa: 1,
      map_visible:true,

      showInfoWindowPosicao:false,
      posicaoInfoWindow:{},
      setas_visible:false,
      eventos_visible:true,

      mensagemSetasOk:false,
      tipo_mensagem:0,
      mensagem_objeto:null,

      markerPlus_visible:false,
      foto_usuario:"",
      trecho_posicao_list:[],
      trecho_posicao_coords:[],
      trecho_evento_list: [],
      posicaoInfoWindowList: [],
      sidenav_visible:true,
      sidenav_div:"sidenav_visible",
      Modals: Modals,
      poligonos_list:[],
      poligonos_cliente_list:[],
      carregarPolygon:false,
      poligono_novo_list:[],
      poligono_novo_latlngs: [],
      carregarPolygonNovo:false,
      selectedPolygon: {},

      FloatActionButton: null,
      count_nao_visualizado:0,

      buttonMapaModo: "primary",
      buttonGridModo: "outline-primary",
      modoMapa: true,
      splitPaneSize:0,
      SplitPaneAllowResize:false,
      polyline_list:[],

      tabClientes_enable:false,
      tabAreas_enable:false,
      mapa_ativo: true,
      grid_size:480,
      GridVeiculos:null,
      loadReadyList:false,
      loading: true,
    }; 
  }
  //-------------------
  
  async componentDidMount() {

    // let url = window.location.toString();
    // let array = url.split('/');
    // let grupo_temporario_id = array[array.length-1]
    // alert(array[array.length-1]);

    var largura = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

    // var altura = window.innerHeight
    // || document.documentElement.clientHeight
    // || document.body.clientHeight;

    this.setState({largura: largura});

    // alert(largura);

    localStorage.setItem('grupo_id', null);
    this.getPosicoesList();
  


    // this.getToken();
    // ToastStore.success(this.props.keey);

    // this.setState({grupo_id: this.props.grupo_id})
   

    // this.carregarChamada(TIPO_CHAMADA_RECARREGAR_CLICK, this.state.veiculo_id_selecionado);

    // this.setState({grupo_id: localStorage.getItem('grupo_id')
    //               ,mapa_ativo: this.props.mapa_ativo});

    // alert(localStorage.getItem('grupo_id')+"  - " + this.state.grupo_id+"  - " + this.props.grupo_id);

    // if(localStorage.getItem('login') !== this.props.login){
    //   this.getToken();
    // }else{
    //   if (localStorage.getItem('token') !==null){
    //     this.setState({token: localStorage.getItem('token')});
    //     this.setState({pessoa_id: this.props.pessoa_id});
    //     this.setState({usuario_id: this.props.usuario_id});
    //     this.setState({nome: this.props.nome});
    //     this.setState({grupo_id: this.props.grupo_id});
    //     this.setState({nome_social: this.props.nome_social});

    //     this.getPosicoesList();
    //   }
    // }

    // if (this.state.modoMapa !==null){
    //   if (this.state.modoMapa) this.onMapaModoClick();
    //   else{
    //     this.onGridModoClick();

    //     if(this.props.mapa_ativo){
    //       this.setState({mapa_ativo: true, splitPaneSize:500, SplitPaneAllowResize:true, segundos: 120});   // , segundos: 30         
    //       // this.setState({mapa_ativo: true, splitPaneSize:500, SplitPaneAllowResize:true, segundos: 30});   // , segundos: 30   
    //     }else{
    //       this.setState({mapa_ativo: false, splitPaneSize:1000, SplitPaneAllowResize:false, segundos: 300});
    //     }

    //   }
    // }


    // this.componentWillMount();

    //------------
    // var instance = new GetPoligonos(this, 5);
    // instance.getPoligonos();
    //------------
  }
//------------
  componentWillMount() {
    // this.setState({
    //   showSheet: false,
    // });

    // var instance = new GetFotoUsuario(this.state.usuario_id, this.state.email, this); 
    // instance.getFoto(this);

  }  
//------------------------------------
  getToken = async () => {
      let body = new FormData()

      body.append('email', this.props.login);
      body.append('senha', this.props.senha);

      localStorage.setItem('login', this.props.login);

      const response = await api.post('/login_api/login', body);
      const {pessoa_id, token, usuario_id, nome, grupo_id, tema_mapa } = response.data;      
      this.setState({pessoa_id: pessoa_id});
      this.setState({token: token});
      localStorage.setItem('token', token);
      this.setState({usuario_id: usuario_id});
      this.setState({nome: nome});
      this.setState({grupo_id: grupo_id});
      this.setState({tema_mapa: tema_mapa});
      // console.log(response.data);
      // ToastStore.success(this.state.token);

      // this.getPosicoesList();
};
//--------------------
setFotoUsuario = (foto_usuario) => {
  this.setState({foto_usuario: foto_usuario});
}
//--------------------
setTerritorio = (pais_id) => {
  var territorio = "Está em território ";
  switch(pais_id) {                                      
    case 1: territorio= territorio+"argentino"; break;                          
    case 2: territorio= territorio+"boliviano"; break;
    case 3: territorio= territorio+"brasileiro"; break;
    case 4: territorio= territorio+"chileno"; break;
    case 5: territorio= territorio+"colombiano"; break;
    case 6: territorio= territorio+"equatoriano"; break;
    case 9: territorio= territorio+"da Guiana Francesa"; break;
    case 10: territorio= territorio+"da Guiana"; break;
    case 11: territorio= territorio+"paraguaio"; break;
    case 12: territorio= territorio+"peruano"; break;
    case 13: territorio= territorio+"surinamês"; break;
    case 14: territorio= territorio+"uruguaio"; break;
    case 15: territorio= territorio+"venezuelano"; break;
    case 16: territorio= territorio+"não identificado"; break;
    default: territorio=""; break;
  };
  return territorio;          
};
 //--------------------
 formatarDateTimeGrid(dtTime){
    //  sex 21:47:10   03-07-2020  
    if(dtTime===null)
      return "";

  const diaSema = String(dtTime).substring(0,3);
  const y = String(dtTime).substring(23,25);
  const mm = String(dtTime).substring(18,20);
  const dd = String(dtTime).substring(12,17);
  const hm = String(dtTime).substring(4,12);    
  const datetime = diaSema+", "+dd+"/"+mm+"/"+y+"   "+hm;  // "Y-MM-DD H:M"
  return datetime;  
}
//--------------------
setGrupoId(grupo_id){
  this.setState({grupo_id: grupo_id});
}
//-----------------
  getPosicoesList = async () => {
    let list_nova=[];
    this.setState({loadReadyList: false});
    try {
      // this.getToken();
      // const token = this.state.token;

      let url = window.location.toString();
      let array = url.split('/');
      let grupo_temporario_id = array[array.length-2];
      let token = array[array.length-1];
      localStorage.setItem('token', token);
      // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibWFybG9ubmdhbHZhb0BnbWFpbC5jb20iLCJleHAiOjE3NDU1MjMyMTV9.ICU8Jpsbceql2JYcWK24qsdmCNgdEEw_bxHj1pjxJnc
      
      let body = new FormData()
      body.append('grupo_temporario_id', grupo_temporario_id);

      body.append('token', String(token));

      const response = await api.post('/jornada_motorista_api/getGrupoTemporarioVeiculo', body);
      
      // console.log("**************************************response*********************************");
      // console.log(response);
      // const response = await posicaoService.get('');
      // ToastStore.error("Statoutoutts: "+response.status);
      // const { posicoesList } =  response.data;
      // this.setState({posicoesList});
      var responseJson = null
      if (response.status !== 403){
        responseJson = JSON.stringify(response.data);

        var req = { posicoes_json: responseJson };
        var resposta = JSON.parse(req.posicoes_json);

        let posicao = this.state.posicao;
        // list = this.state.list;
        
        if (this.state.veiculoPosicao_selecionado ===null)
          if(resposta.length > 0)
            this.setState({center: {lat: resposta[0].latitude, lng: resposta[0].longitude }});

        for (var i=0; i < resposta.length; i++) {
          posicao = {
            index: i+1,
            recebimento_id: resposta[i].recebimento_id,
            veiculo_id: resposta[i].veiculo_id,
            placa: resposta[i].placa,
            latitude: resposta[i].latitude,
            longitude: resposta[i].longitude,
            municipio: resposta[i].municipio,
            uf: resposta[i].uf,
            estado: resposta[i].estado,
            pais_id: resposta[i].pais_id,
            territorio: "",
            pais: resposta[i].pais,
            velocidade: resposta[i].velocidade,
            velocidade2:null,
            data_receb: resposta[i].data_posicao,
            data_receb2: resposta[i].data_receb,
            hora: resposta[i].data_posicao.substring(4, 9),
            dia_semana: resposta[i].data_posicao.substring(0, 3),
            data_format: this.formatarDateTimeGrid(resposta[i].data_posicao),
            diff_minutes:0,
            tecnologia_id: resposta[i].tecnologia_id,
            temperaturas_tab: false,            
            map_visible: true,
            tipo_evento_id: resposta[i].tipo_evento_id,
            MapaKseg:this,
            data_posicao2: resposta[i].data_posicao2,
            versao_equipamento: resposta[i].versao_equipamento,
            ignicao: String(resposta[i].ignicao),
            saida1: String(resposta[i].saida1),
            motorista_id: resposta[i].motorista_id,
            motorista_nome: resposta[i].motorista_nome,
            veiculo_anotacao_id: resposta[i].veiculo_anotacao_id,
            anotacao: resposta[i].anotacao,
          };

          posicao.territorio = this.setTerritorio(posicao.pais_id);

          if(posicao.velocidade > 0 ) posicao.velocidade2 = posicao.velocidade;
            else posicao.velocidade2 = "Parado";

          
          // if(posicao.ignicao == true ) posicao.ignicao = "Ligada";
          //   else posicao.ignicao = "Desligada";  

          // ToastStore.success(resposta[i].veiculo_id);
          // console.log("   -->  resposta[i].temperaturas_tab: ", String(resposta[i].temperaturas_tab));
          if(resposta[i].temperaturas_tab !== null)
            posicao.temperaturas_tab = resposta[i].temperaturas_tab;

          this.setState({veiculo_selecionado_temperatura: resposta[i].temperaturas_tab})  ;
          //---------------
          // let list = this.state.posicoesVeiculo;
          var a = moment();          
          var ano = posicao.data_receb.substring(21,25);
          var mes = posicao.data_receb.substring(18,20);
          var dia = posicao.data_receb.substring(15,17);
          var hora = posicao.data_receb.substring(4,12);
          var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00
          // var b = String(ano+"-"+mes+"-"+dia+"T"+hora);

          const diff = a.diff(b);
          const diffDuration = moment.duration(diff);

          posicao.diff_minutes = parseInt(diffDuration.asMinutes())
          
          // console.log("Total Duration in minutes:", parseInt(diffDuration.asMinutes()));
          //---------------
          if (this.state.veiculoPosicao_selecionado !== null){
            // console.log("veiculoPosicao_selecionado.veiculo_id: "+String(this.state.veiculoPosicao_selecionado.veiculo_id)
                          // +"\n"+ "posicao.veiculo_id: "+String(posicao.veiculo_id));                          

            if(posicao.veiculo_id == this.state.veiculoPosicao_selecionado.veiculo_id){

              this.setState({tipo_chamada: TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO});
              var instance = new GetPosicoesVeiculo(posicao, this.state.grupo_id, this, TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO); 
              instance.getPosicoes();

              this.setState({veiculoPosicao_selecionado: posicao})
              // console.log("posicao.data_receb: ", posicao.data_receb );
              // console.log("São iguais ");
              this.ajustarCentro(posicao);
            }
            // else console.log("Não são iguais ");
          }
          //--------------

          list_nova.push(posicao);
          // this.setState({list: list})
          this.setState({buttonClockTransp: "marg_zero"})
          // Toast.show(String(i+': '+resposta[i].placa), Toast.SHORT);

            this.setFilterList( resposta[i].veiculo_id, resposta[i].placa);          

        }
      }else{
        // this.getToken();
        this.getPosicoesList();
      }


    }    
    catch (response){
      this.setState({errorMessage: response.data});
        ToastStore.error("Error Message getPosicoesList");
      if (response.status ==403){
        // this.getToken();
        this.getPosicoesList();
      }
    }
    finally {  
      this.setState({list: list_nova, loadReadyList: true, loading:false});
    }
    

    if(list_nova.length===1)
      this.ajustarZoom(list_nova[0]);

    this.splashScreenModalClose();
  };
//-----------------
getPosicoesList2 = async () => {
  let list_nova=[];
  this.setState({loadReadyList: false});
  try {
    // this.getToken();
    // const token = this.state.token;

    let body = new FormData()
    // body.append('grupo_id', grupo_id)
    // body.append('grupo_id', localStorage.getItem('grupo_id'));
    // body.append('grupo_id', this.state.grupo_id);
    // alert(this.state.grupo_id);
    if(this.state.grupo_id ===null)
      body.append('grupo_id', this.props.grupo_id);
    else body.append('grupo_id', this.state.grupo_id);

    body.append('token', localStorage.getItem('token'));

    const response = await api.post('/posicao', body);
    // const response = await posicaoService.get('');
    // ToastStore.error("Statoutoutts: "+response.status);
    // const { posicoesList } =  response.data;
    // this.setState({posicoesList});
    var responseJson = null
    if (response.status !== 403){
      responseJson = JSON.stringify(response.data)

      var req = { posicoes_json: responseJson };
      var resposta = JSON.parse(req.posicoes_json);

      let posicao = this.state.posicao;
      // list = this.state.list;
      
      // if (this.state.veiculoPosicao_selecionado ===null)
        // if(resposta.length > 0)
        //   this.setState({center: {lat: resposta[0].latitude, lng: resposta[0].longitude }});

      for (var i=0; i < resposta.length; i++) {
        posicao = {
          index: i+1,
          recebimento_id: resposta[i].recebimento_id,
          veiculo_id: resposta[i].veiculo_id,
          placa: resposta[i].placa,
          latitude: resposta[i].latitude,
          longitude: resposta[i].longitude,
          municipio: resposta[i].municipio,
          uf: resposta[i].uf,
          estado: resposta[i].estado,
          pais_id: resposta[i].pais_id,
          territorio: "",
          pais: resposta[i].pais,
          velocidade: resposta[i].velocidade,
          velocidade2:null,
          data_receb: resposta[i].data_posicao,
          data_receb2: resposta[i].data_receb,
          hora: resposta[i].data_posicao.substring(4, 9),
          dia_semana: resposta[i].data_posicao.substring(0, 3),
          data_format: this.formatarDateTimeGrid(resposta[i].data_posicao),
          diff_minutes:0,
          tecnologia_id: resposta[i].tecnologia_id,
          temperaturas_tab: false,            
          map_visible: true,
          tipo_evento_id: resposta[i].tipo_evento_id,
          MapaKseg:this,
        };

        posicao.territorio = this.setTerritorio(posicao.pais_id);

        if(posicao.velocidade > 0 ) posicao.velocidade2 = posicao.velocidade;
          else posicao.velocidade2 = "Parado";

        if(resposta[i].temperaturas_tab !== null)
          posicao.temperaturas_tab = resposta[i].temperaturas_tab;

        this.setState({veiculo_selecionado_temperatura: resposta[i].temperaturas_tab})  ;
        //---------------
        // let list = this.state.posicoesVeiculo;
        var a = moment();
        var ano = posicao.data_receb.substring(21,25);
        var mes = posicao.data_receb.substring(18,20);
        var dia = posicao.data_receb.substring(15,17);
        var hora = posicao.data_receb.substring(4,12);
        var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00

        const diff = a.diff(b);
        const diffDuration = moment.duration(diff);

        posicao.diff_minutes = parseInt(diffDuration.asMinutes())
        
        // console.log("Total Duration in minutes:", parseInt(diffDuration.asMinutes()));
        //---------------
        if (this.state.veiculoPosicao_selecionado !== null){
          // console.log("veiculoPosicao_selecionado.veiculo_id: "+String(this.state.veiculoPosicao_selecionado.veiculo_id)
                        // +"\n"+ "posicao.veiculo_id: "+String(posicao.veiculo_id));                          

          if(posicao.veiculo_id == this.state.veiculoPosicao_selecionado.veiculo_id){

            this.setState({tipo_chamada: TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO});
            var instance = new GetPosicoesVeiculo(posicao, this.state.grupo_id, this, TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO); 
            instance.getPosicoes();

            this.setState({veiculoPosicao_selecionado: posicao})
            // console.log("posicao.data_receb: ", posicao.data_receb );
            // console.log("São iguais ");
            this.ajustarCentro(posicao);
          }
          // else console.log("Não são iguais ");
        }
        //--------------

        list_nova.push(posicao);
        // this.setState({list: list})
        this.setState({buttonClockTransp: "marg_zero"})

          this.setFilterList( resposta[i].veiculo_id, resposta[i].placa);          

      }
    }else{
      // this.getToken();
      this.getPosicoesList2();
    }


  }    
  catch (response){
    this.setState({errorMessage: response.data});
      ToastStore.error("Error Message getPosicoesList");
    if (response.status ==403){
      // this.getToken();
      this.getPosicoesList2();
    }
  }
  finally {
    let list = this.state.list;

    // Foi feito esse novo loop para somente atualizar o campo de cada linha já existente,
    // e não precisar limpar todo o mapa e o grid.

    if( list.length == 0) list = list_nova;
    else {

      for(var j=0; j<list_nova.length; j++ ){        
        for(var i=0; i<list.length; i++ ){        
          if( list[i].veiculo_id == list_nova[j].veiculo_id){      
            list[i].recebimento_id = list_nova[j].recebimento_id;
            list[i].veiculo_id = list_nova[j].veiculo_id;
            list[i].placa = list_nova[j].placa;
            list[i].latitude = list_nova[j].latitude;
            list[i].longitude = list_nova[j].longitude;
            list[i].municipio = list_nova[j].municipio;
            list[i].uf = list_nova[j].uf;
            list[i].estado = list_nova[j].estado;
            list[i].pais_id = list_nova[j].pais_id;
            list[i].territorio = list_nova[j].territorio;
            list[i].pais = list_nova[j].pais;
            list[i].velocidade = list_nova[j].velocidade;
            list[i].velocidade2  = list_nova[j].velocidade2;
            list[i].data_receb = list_nova[j].data_receb;
            list[i].data_receb2 = list_nova[j].data_receb2;
            list[i].hora = list_nova[j].hora;
            list[i].dia_semana = list_nova[j].dia_semana;
            list[i].data_format = list_nova[j].data_format;
            list[i].diff_minutes = list_nova[j].diff_minutes;
            list[i].tecnologia_id = list_nova[j].tecnologia_id;
            list[i].temperaturas_tab  = list_nova[j].temperaturas_tab;            
            list[i].map_visible = list_nova[j].map_visible;
            list[i].tipo_evento_id = list_nova[j].tipo_evento_id;
          }
        }
        // this.setState({list: list});
      };
    }


    // this.setState({loadReadyList: true});
    // let coords = this.state.coords;
    // coords.push({lat: list[list.length-1].latitude, lng: list[list.length-1].longitude});
    this.setState({list: list, loadReadyList: true});
    // this.setState({list: list, coords:coords, loadReadyList: true});
    // this.setState({list: list});
  }
  

  // if(list_nova.length===1)
  //   this.ajustarZoom(list_nova[0]);

  // this.splashScreenModalClose();
};
  //--------------------------------------------------------------------------------
  getPosicoesData = async (startDate, endDate) => {
    this.setState({splashScreenModalShow:true});
    let body = new FormData();
          body.append('grupo_id', this.state.grupo_id);
          body.append('veiculo_id', this.state.veiculoPosicao_selecionado.veiculo_id);
          // body.append('data_final', String(datetime));
          body.append('token', localStorage.getItem('token'));
    var tipo_chamada =1;

    // var instanceGetPosicoes = new GetPosicoes(this.state.veiculoPosicao_selecionado
    //                               , this
    //                               , "/posicoesVeiculoParada"
    //                               , body
    //                               , tipo_chamada
    //                               , null
    //                               , null);
    
    // this.setState({instanceGetPosicoes: instanceGetPosicoes});                              
    // alert(this.state.posicoesVeiculo.length);
    // instanceGetPosicoes.getPosicoesData(startDate, endDate, this.state.posicoesVeiculo);

    var instance = new GetPosicoesVeiculo(this.state.markerPlus, this.state.grupo_id, this, TIPO_CHAMADA_PLUSCLICK); 
    instance.getPosicoesData(startDate, endDate, this.state.posicoesVeiculo);
  }  
  //-------------------------------------------------------------------------------------
  setFilterList = async (veiculo_id, placa) => {
    let filter_list = this.state.filter_list;
    filter_list.push({value: veiculo_id, label: placa});
    // filter_list.sort((a,b) => a.label - b.label);
    filter_list = filter_list.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({filter_list: filter_list});

  };
  //--------------------
  onClearList = () => {
    // this.setState({ markerPosicao_list: [] });
    // this.removeMarkerList();
    this.setState({ list: [] });
    this.setState({ filter_list: [] });
    this.setState({ markerEvento_list: [] });

    // this.setState({ coords: [] });
    // this.setState({ markerPlus: {} });
  };
//------------------------------------
  getFuck = async () => {
    const URL1 = 'https://cpro41800.publiccloud.com.br/login_api/fuck'
    const URL2 = 'http://localhost:5000/fuck'

    axios.get(URL1).then(res =>{
      // const persons = res.data;
      // console.log(res.data);
      // this.setState({ persons });
    })
  };
//--------------------
  onMarkerPlusClick = (props, marker, e) => {
    this.setState({setas_visible: false
                  , tipo_chamada: TIPO_CHAMADA_PLUSCLICK});

    if( this.state.indexPolylineColor+1  <= this.state.polylineColor_list.length-1)
      this.setState({ indexPolylineColor: this.state.indexPolylineColor+1});
    else this.setState({ indexPolylineColor: this.state.polylineColor_list.length-1});  

    // this.carregarChamada(TIPO_CHAMADA_PLUSCLICK, this.state.veiculo_id_selecionado);
    this.setState({carregandoMaisEventos: true});


    let endDate = this.state.markerPlus.data_receb;
    this.state.Modals.showRangeDataModal(endDate, null);

  };
  //--------------------
  async carregarMarkerPlusClick () {
    var instance = new GetPosicoesVeiculo(this.state.markerPlus, this.state.grupo_id, this, TIPO_CHAMADA_PLUSCLICK); 
    instance.getPosicoes();
  };
//--------------------
onMarkerEventoClick = (props, marker, e) => {
  this.setState({infoWindowEvento: true});
  var markerEvento = null;
  var eventos_list = this.state.eventos_list;

  for(var i=0; i<eventos_list.length; i++ ){
    if( eventos_list[i].recebimento_id == props.name){
      markerEvento = eventos_list[i];
    }
  }


  this.setState({
    selectedPlace: markerEvento.recebimento_id,
    activeMarker: marker,
    marker_aux: marker,
    infoWindowLocation: {lat: markerEvento.latitude, lng: markerEvento.longitude},
    showingInfoWindow: true
  });
};   
//--------------------
onMarkerPosicaoTrechoClick = (props, marker, e) => {
  this.setState({infoWindowEvento: true});
  var markerPosicao = null;
  var trecho_posicao_list = this.state.trecho_posicao_list;

  for(var i=0; i<trecho_posicao_list.length; i++ ){
    if( trecho_posicao_list[i].recebimento_id == props.name){
      markerPosicao = trecho_posicao_list[i];
    }
  }


  this.setState({
    posicaoInfoWindow: markerPosicao,
    activeMarker: marker,
    marker_aux: marker,
    infoWindowLocation: {lat: markerPosicao.latitude, lng: markerPosicao.longitude},
    showingInfoWindowTrecho: true
  });
};  
//--------------------
onMarkerEventoTrechoClick = (props, marker, e) => {
  this.setState({infoWindowEvento: true});
  var markerPosicao = null;
  let list =[];
  var trecho_evento_list = this.state.trecho_evento_list;

  for(var i=0; i<trecho_evento_list.length; i++ ){
    if( trecho_evento_list[i].recebimento_id == props.name){
      markerPosicao = trecho_evento_list[i];
      list.push(trecho_evento_list[i]);
    }
  }

  this.setState({
    posicaoInfoWindow: markerPosicao,
    posicaoInfoWindowList: list,
    activeMarker: marker,
    marker_aux: marker,
    infoWindowLocation: {lat: markerPosicao.latitude, lng: markerPosicao.longitude},
    showingInfoWindowTrecho: true
  });
};  
//---------------------
onMarkerPosicaoClick2 = (props, marker) => {
  this.setState({polylineColor: "#FF0000"})
}
//---------------------
setInfoWindow = (posicao) => {
  this.setState({
    posicaoInfoWindow: posicao,
    infoWindowLocation: {lat: posicao.latitude, lng: posicao.longitude},
    showInfoWindowPosicao: true
  });
}
//---------------------
onMarkerPosicaoClick = (props, marker) => {
  // this.toastss(props.name);
  // this.delete(props.key);
  // this.removeMarkerPosicao(props.title);

  var markerPosicao = null;
  var markerPosicao_list = this.state.markerPosicao_list;

  for(var i=0; i<markerPosicao_list.length; i++ ){
    if( markerPosicao_list[i].recebimento_id == props.name){
      markerPosicao = markerPosicao_list[i];
    }
  }

  if(markerPosicao.tipo_recebimento_id===6)
    this.setState({infoWindowEvento: true});
  else this.setState({infoWindowEvento: false});


  this.setState({
    selectedPlace: markerPosicao.recebimento_id,
    activeMarker: marker,
    marker_aux: marker,
    infoWindowLocation: {lat: markerPosicao.latitude, lng: markerPosicao.longitude},
    showingInfoWindow: true
  });

};
//--------------------
onMouseoverMarker = (props, marker, e) => {
  if (this.state.marker_aux == null){
    this.setState({
      activeMarkerInfoViewEventos: marker,
      marker_aux: marker,
      showingInfoWindow2: true
    });
  }
}
//---------------------
onPolygonClick = (polygon) => {
  this.setState({selectedPolygon: polygon});
  this.state.Modals.showPoligonoOpcoesModal();
};
//-----------------
removeItem=async(item)=>{
  const newItem = this.state.markerEvento_list.filter((evento,index) =>{
    return evento.recebimento_id !== item;
    // return index !== item;
  })

  this.setState({
    markerEvento_list: [...newItem]
  })

  if(newItem.length ===0){
    ToastStore.success("Sem items");
  }
}  
//--------------------
chamadaDeletar= async()=>{
  while(this.state.markerPosicao_list.length!==0)
    this.removeMarkerPosicaoList();
    // this.cleanMarkerPosicao();

  // for(var i=0; i<this.state.markerPosicao_list.length; i++ )
  //   this.removeItem(this.state.markerPosicao_list[i].recebimento_id);

  // for(var i=0; i<this.state.markerEvento_list.length; i++ )
  //   this.removeItem(this.state.markerEvento_list[i].recebimento_id);
}
//----------------------
removeMarkerPosicaoList=async()=>{
  for (var i = 0; i < this.state.markerPosicao_list.length; i++) {
    this.removeMarkerPosicao(this.state.markerPosicao_list[i].recebimento_id)
  }
}
//-----------------
removeMarkerPosicao=async(item)=>{
  ToastStore.success(item);
  const newItem = this.state.markerPosicao_list.filter((evento,index) =>{
    return evento.recebimento_id !== item;
    // return index !== item;
  })

  this.setState({
    markerPosicao_list: [...newItem]
  })

  if(newItem.length ===0){
    ToastStore.success("Sem items");
  }

  // ToastStore.success("  markerEvento_list.length: "+String(this.state.markerEvento_list.length));
}  
//----------------------
removeMarkerList=async()=>{
  for (var i = 0; i < this.state.list.length; i++) {
    this.removeMarker(this.state.list[i].veiculo_id)
  }
}
//-----------------
removeMarker=async(item)=>{
  ToastStore.success(item);
  const newItem = this.state.list.filter((marker,index) =>{
    return marker.veiculo_id !== item;
    // return index !== item;
  })

  this.setState({list: [...newItem]})

  if(newItem.length ===0){
    ToastStore.success("Sem items");
  }

  // ToastStore.success("  markerEvento_list.length: "+String(this.state.markerEvento_list.length));
}  
//----------------------
removeMarkerPosicaoList2em1=async()=>{
  for (var i = 1; i < this.state.markerAux_list.length; i++) {
    this.removeMarkerPosicaoAux(this.state.markerAux_list[i].recebimento_id)
    i=i+1;
  }
}
//-----------------
removeMarkerPosicaoAux=async(item)=>{
  // ToastStore.success(item);
  const newItem = this.state.markerAux_list.filter((evento,index) =>{
    return evento.recebimento_id !== item;
    // return index !== item;
  })

  this.setState({
    markerAux_list: [...newItem]
  })

  if(newItem.length ===0){
    ToastStore.success("Sem items");
  }

  // ToastStore.success("  markerEvento_list.length: "+String(this.state.markerEvento_list.length));
}
//--------------------
  onMarkerClick = (props, marker, e) => {
    // this.callToast("onMarkerClick  "+props.name);
    var veiculo_id = props.name;
    this.setState({veiculo_id_selecionado: veiculo_id
                  ,tipo_chamada: TIPO_CHAMADA_MARKERCLICK_VEICULO
                  ,eventos_visible: false
                  ,eventos_list: []
                  ,indexPolylineColor: 0
                  ,carregarPolyline:false
                  ,polyline_list:[] });
    this.carregarChamada(TIPO_CHAMADA_MARKERCLICK_VEICULO, veiculo_id);  
  
    // this.dateDiff();
  }
  //--------------------
  dateDiff = () => {

    // var today = new Date();
    // var Christmas = new Date("2020-07-03");
    // var diffMs = (Christmas - today); // milliseconds between now & Christmas
    // var diffDays = Math.floor(diffMs / 86400000); // days
    // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes until Christmas 2009 =)");

    // var a = moment();
    // var ano = posicao.data_receb.substring(21,25);
    // var mes = posicao.data_receb.substring(18,20);
    // var dia = posicao.data_receb.substring(15,17);
    // var hora = posicao.data_receb.substring(4,12);
    // var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00

    // const diff = a.diff(b);
    // const diffDuration = moment.duration(diff);
    // const diff_minutes = parseInt(diffDuration.asMinutes())

    // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
    // alert(diff_minutes + " minutes");

  }
  //--------------------
  setBottomSheet = (veiculoPosicao) => {
    if (veiculoPosicao.velocidade>0){
      this.setState({ bsColor: '#229558'  });
      this.setState({ backgroundBottomSheet: 'background_verde'  });
      this.setState({ backgroundBottomSheet2: 'background_verde2'  });
      this.setState({variant_button: "success"});
    }else{
       this.setState({ bsColor: '#ED4145' });
       this.setState({ backgroundBottomSheet: 'background_vermelho'  });
       this.setState({ backgroundBottomSheet2: 'background_vermelho2'  });
       this.setState({variant_button: "danger"});
    }

    if(veiculoPosicao.diff_minutes>=60){
      this.setState({ bsColor: '#616161' });
       this.setState({ backgroundBottomSheet: 'background_cinza'  });
       this.setState({ backgroundBottomSheet2: 'background_cinza2'  });
       this.setState({variant_button: "danger"});
    }
  }
  //--------------------
  async carregarMarkerClick  (veiculo_id)  {

    if(veiculo_id !== null){
    if (this.state.open){
      this.setState({open:false});
      this.setState({ arrow_collapse: require("./img/arrow_collapse_up.png")});
    }

    if(this.state.lineChartTemp !== null)
      this.state.lineChartTemp.setState({temperaturaDataList:[]});

    var veiculoPosicao = null;
    this.setState({ coords: [] });
    this.setState({posicoesVeiculo: []});
    this.setState({ markerPlus: {} });
    this.setState({ markerEvento_list: [] });


    for (var i = 0; i < this.state.list.length; i++) {
      if (this.state.list[i].veiculo_id == veiculo_id){
        veiculoPosicao = this.state.list[i]
        // ToastStore.success(this.state.list[i].placa);
      }
    }

    if(veiculoPosicao !== null){
      this.setState({veiculoPosicao_selecionado: veiculoPosicao});

    // if(veiculoPosicao.temperaturas_tab !== null)
      if(this.state.veiculo_selecionado_temperatura){ 
          this.buttonTemperaturaClick()
      } else this.buttonHistoricoClick()
     

    //------------------------
    // let list = this.state.posicoesVeiculo;
    // var a = moment();
    // var ano = posicao.data_receb.substring(21,25);
    // var mes = posicao.data_receb.substring(18,20);
    // var dia = posicao.data_receb.substring(15,17);
    // var hora = posicao.data_receb.substring(4,12);
    // var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00

    // const diff = a.diff(b);
    // const diffDuration = moment.duration(diff);
    
      
    // console.log("Total Duration in millis:", diffDuration.asMilliseconds());
    // console.log("Total Duration in seconds:", diffDuration.asSeconds());
    // console.log("Total Duration in minutes:", parseInt(diffDuration.asMinutes()));
    // console.log("Days:", diffDuration.days());
    // console.log("Hours:", diffDuration.hours());
    // console.log("Minutes:", diffDuration.minutes());
    // console.log("Seconds:", diffDuration.seconds());
  //------------------------
      // indents.push(<span className='indent' key={i}></span>);
    

      this.setState({ placa: veiculoPosicao.placa });
      this.setBottomSheet(veiculoPosicao);

      if(this.state.modoMapa)
        this.setState({ bsOverflowHeight: 55 });

      this.ajustarZoom(veiculoPosicao);

      // ToastStore.success(veiculoPosicao.data_receb);
      // if(this.state.veiculoPosicao_selecionado !== null){
      //   const instance = new GetPosicoesVeiculo(this.state.veiculoPosicao_selecionado, this.state.grupo_id, this, 1); 
      //   instance.getPosicoes();  
      // }


      // var veiculoPosicao = null;
      // for (var i = 0; i < this.state.list.length; i++) {
      //   if (this.state.list[i].veiculo_id == veiculo_id){
      //     veiculoPosicao = this.state.list[i]
      //     // ToastStore.success(this.state.list[i].placa);
      //   }
      // }

      this.setState({tipo_chamada: TIPO_CHAMADA_MARKERCLICK_VEICULO});
      var instance;
      instance = new GetPosicoesVeiculo(veiculoPosicao, this.state.grupo_id, this, TIPO_CHAMADA_MARKERCLICK_VEICULO); 
      instance.getPosicoes(); 

      // instance = new GetPosicoesVeiculo(this.state.veiculoPosicao_selecionado, this.state.grupo_id, this, 1); 
      // instance.getPosicoes(); 

      // for (var i = 0; i < 50; i++) {
      //   if(this.state.veiculoPosicao_selecionado !== null){
      //     const instance = new GetPosicoesVeiculo(this.state.veiculoPosicao_selecionado, this.state.grupo_id, this, 1); 
      //     instance.getPosicoes();  
      //     break;
      //   }
      // }

      // while(this.state.veiculoPosicao_selecionado === null){
      //   instance = new GetPosicoesVeiculo(veiculoPosicao_selecionado, this.state.grupo_id, this, 1); 
      //   instance.getPosicoes();  
      // }
      }
    }
  };
//-----------------------------------------------------------------------------------------------------  
  // async carregarMarkerClick  (veiculo_id)  {

  //   if(veiculo_id !== null){
  //   if (this.state.open){
  //     this.setState({open:false});
  //     this.setState({ arrow_collapse: require("img/arrow_collapse_up.png"}));
  //   }

  //   if(this.state.lineChartTemp !== null)
  //     this.state.lineChartTemp.setState({temperaturaDataList:[]});

  //   var veiculoPosicao = null;
  //   this.setState({ coords: [] });
  //   this.setState({posicoesVeiculo: []});
  //   this.setState({ markerPlus: {} });
  //   this.setState({ markerEvento_list: [] });


  //   for (var i = 0; i < this.state.list.length; i++) {
  //     if (this.state.list[i].veiculo_id == veiculo_id){
  //       veiculoPosicao = this.state.list[i]
  //       // ToastStore.success(this.state.list[i].placa);
  //     }
  //   }

  //   if(veiculoPosicao !== null){
  //     this.setState({veiculoPosicao_selecionado: veiculoPosicao});

  //   // if(veiculoPosicao.temperaturas_tab !== null)
  //     if(this.state.veiculo_selecionado_temperatura){ 
  //         this.buttonTemperaturaClick()
  //     } else this.buttonHistoricoClick()
     

  //   //------------------------
  //   // let list = this.state.posicoesVeiculo;
  //   // var a = moment();
  //   // var ano = posicao.data_receb.substring(21,25);
  //   // var mes = posicao.data_receb.substring(18,20);
  //   // var dia = posicao.data_receb.substring(15,17);
  //   // var hora = posicao.data_receb.substring(4,12);
  //   // var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00

  //   // const diff = a.diff(b);
  //   // const diffDuration = moment.duration(diff);
    
      
  //   // console.log("Total Duration in millis:", diffDuration.asMilliseconds());
  //   // console.log("Total Duration in seconds:", diffDuration.asSeconds());
  //   // console.log("Total Duration in minutes:", parseInt(diffDuration.asMinutes()));
  //   // console.log("Days:", diffDuration.days());
  //   // console.log("Hours:", diffDuration.hours());
  //   // console.log("Minutes:", diffDuration.minutes());
  //   // console.log("Seconds:", diffDuration.seconds());
  // //------------------------
  //     // indents.push(<span className='indent' key={i}></span>);
    

  //     this.setState({ placa: veiculoPosicao.placa });
  //     this.setBottomSheet(veiculoPosicao);

  //     if(this.state.modoMapa)
  //       this.setState({ bsOverflowHeight: 55 });
    

  //     this.ajustarZoom(veiculoPosicao);

  //     // ToastStore.success(veiculoPosicao.data_receb);
  //     // if(this.state.veiculoPosicao_selecionado !== null){
  //     //   const instance = new GetPosicoesVeiculo(this.state.veiculoPosicao_selecionado, this.state.grupo_id, this, 1); 
  //     //   instance.getPosicoes();  
  //     // }


  //     // var veiculoPosicao = null;
  //     // for (var i = 0; i < this.state.list.length; i++) {
  //     //   if (this.state.list[i].veiculo_id == veiculo_id){
  //     //     veiculoPosicao = this.state.list[i]
  //     //     // ToastStore.success(this.state.list[i].placa);
  //     //   }
  //     // }

  //     this.setState({tipo_chamada: TIPO_CHAMADA_MARKERCLICK_VEICULO});
  //     var instance;
  //     instance = new GetPosicoesVeiculo(veiculoPosicao, this.state.grupo_id, this, TIPO_CHAMADA_MARKERCLICK_VEICULO); 
  //     instance.getPosicoes(); 

  //     // instance = new GetPosicoesVeiculo(this.state.veiculoPosicao_selecionado, this.state.grupo_id, this, 1); 
  //     // instance.getPosicoes(); 

  //     // for (var i = 0; i < 50; i++) {
  //     //   if(this.state.veiculoPosicao_selecionado !== null){
  //     //     const instance = new GetPosicoesVeiculo(this.state.veiculoPosicao_selecionado, this.state.grupo_id, this, 1); 
  //     //     instance.getPosicoes();  
  //     //     break;
  //     //   }
  //     // }

  //     // while(this.state.veiculoPosicao_selecionado === null){
  //     //   instance = new GetPosicoesVeiculo(veiculoPosicao_selecionado, this.state.grupo_id, this, 1); 
  //     //   instance.getPosicoes();  
  //     // }
  //     }
  //   }
  // };
//-------------------------------------------------------------------------------------------------------
  ajustarZoom = (veiculoPosicao) => {
    if(this.state.zooom>6)
      this.setState({zooom: this.state.zooom-10});

    this.setState({
      zooom: this.state.zooom+10,
      center: {
        lat: veiculoPosicao.latitude,
        lng: veiculoPosicao.longitude
      }});
};
//--------------------
zoomMais = (veiculoPosicao) => {
  this.setState({zooom: this.state.zooom+1});
};
//--------------------
zoomMenos = () => {
  this.setState({zooom: this.state.zooom-1});
};
//--------------------
ajustarCentro = (veiculoPosicao) => {
  // console.log(" ajustarCentro - veiculoPosicao.data_receb: ", veiculoPosicao.data_receb );
  this.setState({
    center: {
      lat: veiculoPosicao.latitude,
      lng: veiculoPosicao.longitude
    }});
};
//--------------------------
setCoords = (list, coords, lastPosition, markerEventsList) => {
  var joined = this.state.posicoesVeiculo.concat(list);
  joined.sort((a,b) => b.dateTime - a.dateTime);

  // joined.sort((a,b) => {
  //   return new Date(b.data_receb).getTime() - 
  //       new Date(a.data_receb).getTime()
  // });

  this.setState({posicoesVeiculo: joined, eventosNovos_list: markerEventsList });
  var coords = [];
  for (var i=0; i <joined.length; i++) {
    coords.push({lat: joined[i].latitude, lng: joined[i].longitude });
    // console.log(" joined.dateTime: "+String(joined[i].data_receb) );
  }
  this.setState({coords: coords});

  var veiculos_list = this.state.list; 
  for (var i=0; i<veiculos_list.length; i++) {
    if( veiculos_list[i].veiculo_id !== undefined && joined[0].veiculo_id !== undefined)
      if(veiculos_list[i].veiculo_id === joined[0].veiculo_id){
        // console.log(" joined encontrou");
        veiculos_list[i].latitude = joined[0].latitude;
        veiculos_list[i].longitude = joined[0].longitude;
        veiculos_list[i].velocidade = joined[0].velocidade;
        veiculos_list[i].data_receb = joined[0].data_receb;
        this.setState({list:veiculos_list, veiculoPosicao_selecionado: veiculos_list[i]});
        break;
      }
  }

  this.setState({markerPlus: lastPosition});

  var markerEvento_list = this.state.markerEvento_list.concat(markerEventsList);
  this.setState({markerEvento_list: markerEvento_list});
  
  if(this.state.carregarPolyline === false)
    this.setState({carregarPolyline:true});


  // this.setState({setas_visible:true});
  this.setState({eventos_visible: true});
  this.setState({markerPlus_visible: true});
  this.splashScreenModalClose();
};
//--------------------------
setCoords2 = (coords, lastPosition, markerEventsList) => {
  var joined = this.state.coords.concat(coords);
  this.setState({coords: joined});
  
  this.setState({markerPlus: lastPosition});

  var markerEvento_list = this.state.markerEvento_list.concat(markerEventsList);
  this.setState({markerEvento_list: markerEvento_list});
  
  if(this.state.carregarPolyline === false)
    this.setState({carregarPolyline:true});


  this.splashScreenModalClose();
};
//--------------------------
pushCoords = async(list, coordss, markerEventsList, firstPosition) => { 
  try{
    var posicoesVeiculo_list =  this.state.posicoesVeiculo;
    // console.log("    firstPosition  latitude: "+String(firstPosition.latitude)+"   longitude: "+String(firstPosition.longitude));
    // console.log(" joined firstPosition:   receb_id: "+String(firstPosition.recebimento_id)+"   data_receb: "+String(firstPosition.data_receb) +"   latitude: "+String(firstPosition.latitude)+"   longitude: "+String(firstPosition.longitude));
    
    this.setState({carregarPolyline:false})
    var achou=false;
    var coords = [];
    var list_novo = [];



    // console.log("-------- joined  list Load -------");
    // console.log(" joined  list.length: "+String(list.length));

    //----1º se nao houver duplicados (entre list e posicoesVeiculo_list), entao da um push no list_novo
    // for (var i = 0; i < list.length; i++) {
    //   // console.log(" joined  list[i] latitude: "+String(list[i].latitude)+"   longitude: "+String(list[i].longitude));
    //   achou = false;
    //   for (var j = 0; j < posicoesVeiculo_list.length; j++) {
    //     if (list[i].latitude == posicoesVeiculo_list[j].latitude)
    //       if (list[i].longitude == posicoesVeiculo_list[j].longitude){
    //         achou = true;
    //         break;
    //       }
    //   }
    //   if(achou===false){
    //       list_novo.push(list[i]);
    //   }
    // }

    //----2º join dos novos, com os que ja tinha
    // var joined = list_novo.concat(posicoesVeiculo_list);
    var joined = posicoesVeiculo_list;

    //----3º Sort
    joined.sort((a,b) => b.dateTime - a.dateTime);

    //----4º push nos objetos coords
    // console.log(" joined.length: "+String(joined.length));
    for (var i = 0; i < joined.length; i++) {
      coords.push({lat: joined[i].latitude, lng: joined[i].longitude });
      // console.log(" joined["+i+"]:   receb_id: "+String(joined[i].recebimento_id)+"   data_receb: "+String(joined[i].data_receb) +"   latitude: "+String(joined[i].latitude)+"   longitude: "+String(joined[i].longitude));
      // console.log(" joined["+i+"]:      latitude: "+String(list[i].latitude)+"   longitude: "+String(list[i].longitude));
    }

    //---5º Atualizar o state das duas listas
    // this.setState({coords: coords, posicoesVeiculo: joined});
    

                  //  recebimento_id: event[i].recebimento_id,
                  //  veiculo_id: event[i].veiculo_id,
                  //  placa: event[i].placa,
                  //  latitude: event[i].latitude,
                  //  longitude: event[i].longitude,
                  //  velocidade: event[i].velocidade,
                  //  data_receb: event[i].data_posicao,
                  //  hora: event[i].data_posicao.substring(4, 9),
                  //  dia_semana: event[i].data_posicao.substring(0, 3),
                  //  diff_minutes:0,
                  //  tecnologia_id: event[i].tecnologia_id,
                  //  temperaturas_tab: false

    this.setState({carregarPolyline:true } );

    var veiculos_list = this.state.list; 
    for (var i=0; i<veiculos_list.length; i++) {
      if(veiculos_list[i].veiculo_id !== undefined && joined[0].veiculo_id !== undefined)
        if(veiculos_list[i].veiculo_id === joined[0].veiculo_id){
          // console.log(" joined encontrou 2");
          veiculos_list[i].latitude = joined[0].latitude;
          veiculos_list[i].longitude = joined[0].longitude;
          veiculos_list[i].velocidade = joined[0].velocidade;
          veiculos_list[i].data_receb = joined[0].data_receb;
          // this.setState({list: veiculos_list, veiculoPosicao_selecionado: joined[0]});
          // this.setState({list: veiculos_list, veiculoPosicao_selecionado: veiculos_list[i]});
          this.setBottomSheet(veiculos_list[i]);
          break;
        }
    }

    // var veiculos_list = this.state.list; 
    // let joined_last = joined.length-1;
    // for (var i=0; i<veiculos_list.length; i++) {
    //   if(veiculos_list[i].veiculo_id === joined[joined_last].veiculo_id){
    //     // console.log(" joined encontrou 2");
    //     veiculos_list[i].latitude = joined[joined_last].latitude;
    //     veiculos_list[i].longitude = joined[joined_last].longitude;
    //     veiculos_list[i].velocidade = joined[joined_last].velocidade;
    //     veiculos_list[i].data_receb = joined[joined_last].data_receb;
    //     this.setState({list: veiculos_list, veiculoPosicao_selecionado: joined[joined_last]});
    //     this.setBottomSheet(veiculos_list[i]);
    //     break;
    //   }
    // }

    // console.log(" joined.length: "+String(joined.length));
    // for (var i=0; i<joined.length; i++) {
      // console.log(" joined["+i+"]:   receb_id: "+String(joined[i].recebimento_id)+"   data_receb: "+String(joined[i].data_receb) +"   latitude: "+String(joined[i].latitude)+"   longitude: "+String(joined[i].longitude));
      // console.log(" joined["+k+"]:   receb_id: "+String(list[k].recebimento_id)+"   data_receb: "+String(list[k].data_receb) +"   latitude: "+String(list[k].latitude)+"   longitude: "+String(list[k].longitude));
    // }
    // this.splashScreenModalClose();
  }catch (response){}
};
//--------------------------
pushCoords2 = async(coordss, markerEventsList, firstPosition) => { 
  // var joined = this.state.coords.concat(coords);
  // this.setState({coords: joined});

  //---------------
  var coordenadas = [];
  // console.log("    firstPosition  latitude: "+String(firstPosition.latitude)+"   longitude: "+String(firstPosition.longitude));
  // coordenadas.push({lat: firstPosition.latitude, lng: firstPosition.longitude});
  // coordenadas = coordenadas.concat(coords);
  // coords = coordenadas;

  this.setState({carregarPolyline:false})
  var coordsList = this.state.coords;
  var posicoesVeiculo = this.state.posicoesVeiculo;
  var coords = [];
  coords.push({lat: firstPosition.latitude, lng: firstPosition.longitude});



  // console.log("-------- joined  coords Load -------");
  var c;
  // console.log(" joined  coordss.length: "+String(coordss.length));
  for (var i = coordss.length-1; i >= 0; i--) {
    // console.log(" joined  coords[i] lat: "+String(coordss[i].lat)+"   lng: "+String(coordss[i].lng));
    achou = false;
    for (var j = 0; j < coords.length; j++) {
      if (coordss[i].lat == coords[j].lat)
        if (coordss[i].lng == coords[j].lng){
          achou = true;
          break;
        }
    }
    if(achou===false){
          c = {lat: coordss[i].lat, lng: coordss[i].lng }
          coords.push(c);
          // console.log(" joined  coords Entrou ");
    }
  }

  
  // this.setState({markerAux_list: posVeiculo})

  // var len = coords.length;
  // console.log(" 1coords.lenght: "+String(this.state.coords.length));
  // for (var i = 0; i < len; i++) {
  //   console.log(" coords[i].lat: "+String(coords[i].lat));
  //   coordsList.push(coords[i]);
  // }
  // this.setState({coords: coordsList});
  
  //---------------------------------------------------------
  // for (var i = 0; i < coords.length; i++) {
  //   console.log(" ffff0  coords[i].lat: "+String(coords[i].lat)+"   lng: "+String(coords[i].lng));
  // }
  // console.log("\n");

  // console.log(" 1coords.lenght: "+String(coords.length));
  // var achou; fiz isso para nao repetir as coordenadas que ja estao na list
  var achou;
  // for (var i = coordsList.length-1; i >=0; i--) {
  for (var i = 0; i < coordsList.length-1; i++) {  
    // console.log(" ffff1  coords[i] lat: "+String(coordsList[i].lat)+"   lng: "+String(coordsList[i].lng));
    achou = false;
    for (var j = 0; j < coords.length; j++) {
      if (coordsList[i].lat == coords[j].lat)
        if (coordsList[i].lng == coords[j].lng){
          achou = true;
          break;
        }
    }
    if(achou===false)
          coords.push(coordsList[i]);
  }
  // console.log("\n");
  // coords = coords.concat(coordss);

  // for (var i = 0; i < coords.length; i++) {
  //   console.log(" ffff2  coords[i].lat: "+String(coords[i].lat)+"   lng: "+String(coords[i].lng));
  // }

  // var joined = coords.concat(coordss);
  // this.setState({coords: joined});
  

  this.setState({coords: coords});
  
  // console.log("\n &&&*  O que eu quero ver:");
  // for (var i = 0; i < joined.length; i++) {
  //   console.log(" joined  coords[i].lat: "+String(joined[i].lat)+"   lng: "+String(joined[i].lng));
  // }
  // this.setState({coords: coords});
//---------------------------------------------------------
  // console.log(" 2coords.lenght: "+String(this.state.coords.length));
  var markerEvento_list = this.state.markerEvento_list.concat(markerEventsList);
  this.setState({markerEvento_list: markerEvento_list});
  //---------------

  this.setState({carregarPolyline:true})


  // this.splashScreenModalClose();
};
//-------------------------
setPosicoesVeiculo = async(posVeiculo)=>{
  // ToastStore.success(" posVeiculo.lenght: "+String(posVeiculo.length));
  var posList = this.state.posicoesVeiculo;
  var posVeiculo1 = posVeiculo;
  // ToastStore.success(" posList.lenght: "+String(posList.length));
  // console.log(" posList.lenght: "+String(posList.length));
  
  // this.setState({markerAux_list: posVeiculo})
  var len = posVeiculo.length;
  // ToastStore.success(" posVeiculo.lenght: "+String(len));
  // console.log(" posVeiculo.lenght: "+String(len));
  // for (var i = 0; i < len-2; i++) {
  //   console.log(posVeiculo[i].data_receb);
  //   posList.push(posVeiculo[i]);
  // }
  // this.setState({posicoesVeiculo: posList});

  
  if(posList.length == 0){
    // ToastStore.success(" posVeiculo.lenght: "+String(len));
    // console.log(" posVeiculo.lenght: "+String(len));
    for (var i = 0; i < len; i++) {
      // console.log(posVeiculo[i].data_receb+"   recebimento_id: "+posVeiculo[i].recebimento_id);
      posList.push(posVeiculo[i]);
    }
    this.setState({posicoesVeiculo: posList});
  }else{
    for (var i = 0; i < posList.length; i++) {
      // console.log(posList[i].data_receb);
      posVeiculo1.push(posList[i]);
    }
    this.setState({posicoesVeiculo: posVeiculo1});
  }

  


  // this.setState({carregarFlechas:false})
  // // this.setState({markerPosicao_list: []});
  let markerList = this.state.markerPosicao_list;
  markerList.push(posVeiculo[0]);
  // console.log(" ***PRIMEIRA POSICAO lat: "+String(posVeiculo[0].latitude)+"   lng: "+String(posVeiculo[0].longitude));
  // console.log(markerList[0]);
  // this.setState({markerPosicao_list: posVeiculo});

  // console.log("markers antes:");
  for (var i = 0; i < markerList.length; i++) {
    // console.log(" markers0  markerList[i].lat: "+String(markerList[i].latitude)+"   lng: "+String(markerList[i].longitude)+"  recebimento_id: "+String(markerList[i].recebimento_id)+"   data_receb: "+String(markerList[i].data_receb));
  }
  // console.log("\n");

  for (var i = 0; i < posVeiculo.length; i++) {
    // console.log(" inserir  posVeiculo[i].lat: "+String(posVeiculo[i].latitude)+"   lng: "+String(posVeiculo[i].longitude)+"   data_receb: "+String(posVeiculo[i].data_receb));
    markerList.push(posVeiculo[i]);
  }
  // this.setState({markerPosicao_list: markerList});

  // console.log("\nmarkers depois:");
  for (var i = 0; i < markerList.length; i++) {
    // console.log(" markers1  markerList[i].lat: "+String(markerList[i].latitude)+"   lng: "+String(markerList[i].longitude)+"  recebimento_id: "+String(markerList[i].recebimento_id)+"   data_receb: "+String(markerList[i].data_receb));
  }
  // console.log("\n");

  var mList = [];
  var achou;
  
  if(this.state.markerPosicao_list.length==0)
    mList.push(markerList[0]);

  for (var i = 0; i < markerList.length; i++) {
    // console.log(" novo  markerList[i] lat: "+String(markerList[i].latitude)+"   lng: "+String(markerList[i].longitude));
    achou = false;
    for (var j = 0; j < mList.length; j++) {
      if (markerList[i].latitude == mList[j].latitude)
        if (markerList[i].longitude == mList[j].longitude){
          achou = true;
          break; 
        }
    }
    if(achou==false)
      if(markerList[i].veiculo_id == posVeiculo[0].veiculo_id)
        mList.push(markerList[i]);
  }

  this.setState({markerPosicao_list: mList});

  // console.log("\nmarkers novo:");
  for (var i = 0; i < mList.length; i++) {
    console.log(" mList[i].lat: "+String(mList[i].latitude)+"   lng: "+String(mList[i].longitude)+"  veiculo_id: "+String(mList[i].veiculo_id)+"  recebimento_id: "+String(mList[i].recebimento_id)+"   data_receb: "+String(mList[i].data_receb));
  }

  
  // var instance = new GetEventos(this.state.markerPlus, this, posList);
  // var eventosNovos_list = this.state.eventosNovos_list;
  let eventosNovos_list = [];
  eventosNovos_list = eventosNovos_list.concat(this.state.eventosNovos_list);
  var tipo_chamada = 0;  // TIPO_CHAMADA_POSICOES
  var instance = new GetEventos(this.state.markerPlus, this, eventosNovos_list, tipo_chamada, posVeiculo[0].veiculo_id, null);
  instance.getEventos();


  // for (var i = 0; i < len; i++) {
  //   if(i != len-1){
  //     markerList.push(posVeiculo[i]);
  //   }
  // }

  // if(markerList.length>0){
    // this.setState({carregarFlechas:false})
  //   this.setState({markerPosicao_list: []});
  // }else{
  //   this.setState({markerPosicao_list: posVeiculo});
  //   this.setState({carregarFlechas:true})
  // }

  // if(len >50){
  //   ToastStore.success(" Eh maior que 50 ");
  //   this.removeMarkerPosicaoList2em1();
  // }
  // ToastStore.success(" markerAux_list.lenght: "+String(this.state.markerAux_list.length));

  // let posMarkerList = this.state.markerPosicao_list;
  // let lenMarker = this.state.markerAux_list.length;
  // for (var i = 0; i < lenMarker; i++) {
  //   if(i != lenMarker-1){
  //     posMarkerList.push(this.state.markerAux_list[i]);
  //   }
  // }
  // this.setState({markerPosicao_list: posMarkerList});


  // this.setState({markerPosicao_list: this.state.markerAux_list});
  // this.setState({carregarFlechas:true})

  // if(this.state.markerPosicao_list.length===0){
  //   this.setState({markerPosicao_list: posVeiculo});
  //   this.setState({carregarFlechas:true})
  // }

  // this.renderFlechas();
  // if(this.state.markerPosicao_list.length>0)
    // this.setState({carregarFlechas:true})

  this.splashScreenModalClose();
}
//--------------------------
pushTrechoPosicao = async(coords, trecho_posicao_list) =>{
  this.setState({trecho_posicao_coords: coords
                 ,trecho_posicao_list: trecho_posicao_list});
  }
//--------------------------
pushTrechoEventos = async(trecho_evento_list) =>{
  this.setState({trecho_evento_list: trecho_evento_list});                 
}
//--------------------------
setPolyline  (polyline, coords) {
  let poly =   <Polyline
      path={coords}
      strokeColor={this.state.polylineColor_list[this.state.indexPolylineColor]}
      strokeOpacity={1.8}
      strokeWeight={5} />  ;

  polyline.polyline = poly;

  return polyline;
};
//--------------------------
renderPolyline1 =  () => {
  // ToastStore.success("renderPolyline coordes.lenght: "+String(this.state.coords.lenght));
  let polyline_list = this.state.polyline_list;
  let strokeWeight = 5;

  // if(polyline_list.length  ===1)
  //   strokeWeight = 1;

  let poly =   <Polyline
  path={this.state.coords}
  strokeColor={this.state.polylineColor_list[this.state.indexPolylineColor]}
  strokeOpacity={1.8}
  strokeWeight={strokeWeight} />  ;

  polyline_list.push(
poly
  );

  

  return( 
    polyline_list.map(polyline =>   
      polyline
     )                       
  )
};
//--------------------------
renderPolyline =  () => {
  // ToastStore.success("renderPolyline coordes.lenght: "+String(this.state.coords.lenght));
  return( 
        <Polyline
          path={this.state.coords}
          strokeColor={this.state.polylineColor_list[2]}
          strokeOpacity={1.8}
          strokeWeight={5} />                                
  )
};
//--------------------------
renderPolylinee =  () => {
  // ToastStore.success("renderPolyline coordes.lenght: "+String(this.state.coords.lenght));
  return( 
        <Polyline
          path={this.state.coords}
          strokeColor={this.state.polylineColor_list[0]}
          strokeOpacity={1.8}
          strokeWeight={2} />                                
  )
};
//--------------------------
renderPolyline2 =  () => {
  // ToastStore.success("renderPolyline coordes.lenght: "+String(this.state.coords.lenght));
  var poly=[];
  poly.push(this.state.coords[this.state.coords.length-2]);
  poly.push(this.state.coords[this.state.coords.length-1]);
  return(
    <Polyline
    path={poly}
    strokeColor="#FF0000" // vermelho
    strokeOpacity={1.8}
    strokeWeight={10} />       
  )  
};
//--------------------------
renderPolyline3 =  () => {
  // ToastStore.success("renderPolyline coordes.lenght: "+String(this.state.coords.lenght));
  var poly=[];
  poly.push(this.state.coords[0]);
  poly.push(this.state.coords[1]);
  return(
    <Polyline
    path={poly}
    strokeColor="#008000" // verde
    strokeOpacity={1.8}
    strokeWeight={10} />       
  )
};
//--------------------------
renderPolygon =  () => {
  return( 
      this.state.poligonos_list.map((polygon, index) =>     
        polygon.dados[0].tipo_raio ?          
          <Circle
            radius={polygon.dados[0].raio} 
            center={polygon.lat_lng_list[0]}
            // onMouseover={() => console.log('mouseover')}
            // onClick={() => console.log('click')}
            // onMouseout={() => console.log('mouseout')}
            strokeColor='transparent'
            strokeOpacity={0}
            strokeWeight={5}
            fillColor={polygon.cor}
            fillOpacity={0.2}
        />
        :
          <Polygon
            paths={polygon.lat_lng_list}
            strokeColor="#000000"
            strokeOpacity={0.8}
            strokeWeight={0.5}
            fillColor={polygon.cor}
            fillOpacity={0.20}
            title={String(polygon.descricao)}
            onClick={()=>this.onPolygonClick(polygon)} />
          // onClick={()=>this.callToast(polygon.dados[0].descricao)} /> 
      )  
  )
};
//--------------------------
renderPolygonClientes =  () => {
  return( 
      this.state.poligonos_cliente_list.map((polygon, index) =>     
        polygon.dados[0].tipo_raio ?          
          <Circle
            radius={polygon.dados[0].raio} 
            center={polygon.lat_lng_list[0]}
            // onMouseover={() => console.log('mouseover')}
            // onClick={() => console.log('click')}
            // onMouseout={() => console.log('mouseout')}
            strokeColor='transparent'
            strokeOpacity={0}
            strokeWeight={5}
            fillColor={polygon.cor}
            fillOpacity={0.20}
        />
        :
          <Polygon
            paths={polygon.lat_lng_list}
            strokeColor="#000000"
            strokeOpacity={0.8}
            strokeWeight={0.5}
            fillColor={polygon.cor}
            fillOpacity={0.20}
            title={String(polygon.descricao)}
            onClick={()=>this.onPolygonClick(polygon)} />
          // onClick={()=>this.callToast(polygon.dados[0].descricao)} /> 
      )  
  )
};
//--------------------------
renderPolygonNovo =  () => {
  var poligono_novo_latlngs = this.state.poligono_novo_latlngs;
  // console.log("poligono_novo_latlngs.length:");
  // console.log(poligono_novo_latlngs.length);
  // console.log("renderPolygonNovo poligono_novo_latlngs:");
  // console.log(poligono_novo_latlngs);
  // console.log(poligono_novo_latlngs.lat_lng_list.length);
  if(poligono_novo_latlngs.lat_lng_list.length>0){
  return( 
      // this.state.poligono_novo_list.map((polygon, index) =>               
        <Polygon
          paths={this.state.poligono_novo_latlngs.lat_lng_list}
          strokeColor="#000000"
          strokeOpacity={0.8}
          strokeWeight={0.5}
          fillColor={this.state.poligono_novo_latlngs.cor}
          fillOpacity={0.20}
          title={String(poligono_novo_latlngs.descricao)}
          onClick={()=>this.onPolygonClick(poligono_novo_latlngs)} />
          // onClick={()=>this.callToast(polygon.dados[0].descricao)} /> 
      // )  
  )
}
};
//--------------------------
renderMarkerPolygonNovo =  () => {
  // this.callToast("Polygon novo "+String(this.state.poligono_novo_list.length));
  // console.log(this.state.poligonos_list);
  return( 
      this.state.poligono_novo_list.map((polygon, index) =>               
        <Marker 
          key={index}
          title={String(polygon.id)}
          name={index}
          position={{lat: polygon.latitude, lng: polygon.longitude}}
          onClick={this.onMarkerPolygonClick}
          draggable={true}
          // onDragend={this.moveMarker2.bind(this)}
          onDragend={this.moveMarker}
        
          icon={{url: polygon.flag
            ,anchor: new this.props.google.maps.Point(32,60)
            ,scaledSize: new this.props.google.maps.Size(64,64)}}
        
        />
      )  
  )
};
//----------------
moveMarker=(mapProps, map)=> {
  const lat = map.position.lat();
  const lng = map.position.lng();

  var poligono_novo_latlngs = this.state.poligono_novo_latlngs;
  poligono_novo_latlngs.lat_lng_list[map.name].lat = lat;
  poligono_novo_latlngs.lat_lng_list[map.name].lng = lng;

  var poligono_novo_list = this.state.poligono_novo_list;
  poligono_novo_list[map.name].latitude = lat;
  poligono_novo_list[map.name].longitude = lng;

  this.setState({carregarPolygonNovo: false, poligono_novo_list:poligono_novo_list, poligono_novo_latlngs: poligono_novo_latlngs});
  // this.setState({poligono_novo_latlngs: poligono_novo_latlngs});
  this.setState({carregarPolygonNovo: true});
  // for(var i=0;i<poligono_novo_latlngs.lat_lng_list.length;i++){
  //   if(poligono_novo_latlngs.lat_lng_list[i])
  // }
}
//-------------
moveMarker2(marker){
  this.callToast("marker "+String(marker.position.lat+", "+marker.position.lng));
  // console.log("marker");
  // console.log(marker);
};
//-------------------------
onMarkerPolygonClick = (props, marker) => {
  ToastStore.success(String("onMarkerPolygonClick"));
}
//-------------------------
toastss = (direcao) =>{
  ToastStore.success(String(direcao));
}
//--------------------------
renderFlechas  = () => {
  let marker="";
  let marcador="";
  return(
          this.state.markerPosicao_list.map(posVeiculo =>              
              <Marker 
                key={posVeiculo.recebimento_id}
                title={posVeiculo.recebimento_id}
                name={posVeiculo.recebimento_id}
                position={{lat: posVeiculo.latitude, lng: posVeiculo.longitude}}
                onClick={this.onMarkerPosicaoClick}

                {...posVeiculo.direcao===0 ? marcador=require('./img/point_position.png') : marcador=require('./img/point_position4_3.png')}
                {...marker=
                  'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" '
                 +' height="20px" width="20px" > ' 
                 +' <g transform="rotate('+posVeiculo.direcao+' 10 10)"  >'
                 +'  <image xlink:href="'+marcador+'"  />   '  
                 +' </g >'
                 +''                                   
                 +''
                 + '   </svg> '
               }
               
               icon={{url: marker
                  ,anchor: new this.props.google.maps.Point(10,10)}}
                
                />
              )
                
  )
};
//---------------
renderFlechas2  = () => {
  // PostMarkerPosicao(this.state.markerPosicao_list);
  
  // return(
  //   // <PostMarkerPosicao markerPosicao_list={this.state.markerPosicao_list}/>
  //   <PostMarkerPosicao={this.state.markerPosicao_list}/>
  // )
};
//-------------------
cleanMarkerPosicao = async() =>{
  this.removeMarkerPosicaoList();
  // this.setState({ markerPosicao_list: [] });
}
//-------------------
buttonHistoricoClick = () =>{
  this.setState({ tabHistoricoSel: true  
          , tabTemperaturaSel: false
          , activeIndexCarousel:0 });
};  
//-------------------
buttonTemperaturaClick = () =>{
  this.setState({ tabHistoricoSel: false
          , tabTemperaturaSel: true
          , activeIndexCarousel:2 });
};  
//-------------------
closeBottomSheet = () =>{
  if(this.state.open)
    this.toggleBottomSheet();

  this.setState({ bsOverflowHeight: 0 });
};  
//--------------------
  toastStore2 = () => {
    ToastStore.success("teste2");
  };
  //--------------------
  callToast = (texto) => {
    ToastStore.success(texto);
  };
  //--------------------
  toast = (texto) => {
    ToastStore.success(texto);
  };
  //--------------------
  callToastError = (texto) => {
    ToastStore.error(texto);
  };
  //--------------------
  toastError = (texto) => {
    ToastStore.error(texto);
  };
  //--------------------
  setasVisible = () => {
    this.setState({setas_visible: !this.state.setas_visible});
    
    if(!this.state.setas_visible){
      let imagem = require('./img/img_flechas.png');
      let texto = "O Carregamento de muitas Setas de posição ocasionam sobrecarga no mapa. \n Para evitar lentidão, ative as Setas somente quando for necessário.";
      this.state.Modals.showMensagemModal(this, "Aviso!", texto, false, imagem);
    }

    // this.setState({eventos_visible: !this.state.eventos_visible});
    // for(var i=0; i<this.state.eventos_list.length; i++ ){
    //   console.log(" eventos_list.receb: "+String(this.state.eventos_list[i].recebimento_id))
    // }
    // console.log(" eventos_list.receb:------------------------------------------------")
  };
  //------------------------------------------------
  confirmarMensagem = (texto) => {}
  //---------------------------------------------------------
  recarregarPosicoesClick = () => {
    this.setState({tipo_chamada: TIPO_CHAMADA_RECARREGAR_CLICK});
    this.carregarChamada(TIPO_CHAMADA_RECARREGAR_CLICK, this.state.veiculo_id_selecionado);   
    this.setState({veiculo_id_selecionado: null});
    this.closeBottomSheet();
    this.setState({eventos_visible: false});
    this.setState({setas_visible: false});
    this.setState({carregarPolyline: false});
    this.setState({markerPlus_visible: false});
    this.setState({veiculoPosicao_selecionado: null,
      posicoesVeiculo: [],
      coords: [],
      markerPlus: {},
      poligonos_list: [],
      carregarPolygon: false });

    this.setState({completions: this.state.completions + 1});

    this.setState({trecho_posicao_list: []
                  ,trecho_posicao_coords: []
                  ,posicaoInfoWindowList: []})  

    this.onClearList();  // isso ja faz recarregar a lista das posicoes

    if(this.state.GridVeiculos !== null)
      this.state.GridVeiculos.onValueChangedGrupo(this.state.grupo_id);

    this.getPosicoesList();


    if(this.state.Modals !== null){
      if(this.state.Modals.state.NotificacaoModal !== null){
        this.state.Modals.state.NotificacaoModal.setState({list:[]});
      }
    }
    
    // this.setState({veiculoPosicao_selecionado: null,
    //                 posicoesVeiculo: [],
    //                 coords: [],
    //                 markerPlus: {} });
    
  };
  //--------------------
  recarregarPosicoesCountdown_backup = () => {
    // ToastStore.success("Recarregar Posicoes Countdown ");

    this.carregarChamada(TIPO_CHAMADA_RECARREGAR_CLICK, this.state.veiculo_id_selecionado);

    this.setState({completions: this.state.completions + 1},)

    this.onClearList();  // isso ja faz recarregar a lista das posicoes

    this.getPosicoesList();
    // this.restartClock();


    if(this.state.Modals !== null){
      if(this.state.Modals.state.NotificacaoModal !== null){
        this.state.Modals.state.NotificacaoModal.setState({list:[]});
      }
    }

 
  };
  //--------------------
  recarregarPosicoesCountdown = () => {
    // ToastStore.success("Recarregar Posicoes Countdown ");

    if(this.state.list.length > 0){
      this.carregarChamada(TIPO_CHAMADA_RECARREGAR_CLICK, this.state.veiculo_id_selecionado);

      this.setState({completions: this.state.completions + 1},)
      this.setState({filter_list: []});

      // this.onClearList();  // isso ja faz recarregar a lista das posicoes

      this.getPosicoesList2();
      // this.restartClock();


      if(this.state.Modals !== null){
        if(this.state.Modals.state.NotificacaoModal !== null){
          this.state.Modals.state.NotificacaoModal.setState({list:[]});
        }
      }
    } 
 
  };  
  //-------------------------------------------------------------------
  recarregarPosicoesCountdown2 = () => {
    // ToastStore.success("Recarregar Posicoes Countdown ");

    // this.carregarChamada(TIPO_CHAMADA_RECARREGAR_CLICK, this.state.veiculo_id_selecionado);

    this.setState({completions: this.state.completions + 1},)

    // this.onClearList();  // isso ja faz recarregar a lista das posicoes

    this.getPosicoesList2();
    // this.restartClock();


    // if(this.state.Modals !== null){
    //   if(this.state.Modals.state.NotificacaoModal !== null){
    //     this.state.Modals.state.NotificacaoModal.setState({list:[]});
    //   }
    // }

  };  
  //-----------------
  sleep (milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }
  //--------------------
  restartClock = async() =>{
    await this.sleep(10000);
    this.setState(
      {
        completions: this.state.completions + 1
      },
    )
  }
  //--------------------
  mapZoom = () => {
    return (      
        <Map google={this.props.google} zoom={25}/>
      );
  };
//------------------------------------
  onPressZoomIn(lstLat, lstLong, latDelta, longDelta) {
    var region = {
      latitude:   lstLat,
      longitude:  lstLong,
      latitudeDelta: latDelta - 7.5900,
      longitudeDelta: longDelta - 7.5400
      }
    // this._map.animateToRegion(region, 100);
  }
  //-----------------------------
  mapClicked=(mapProps, map, event)=> {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        eventoClicado_list: []
      })
    }        


    //-------------Polygon{-----------------
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // -- Markes Flag---
    if(this.state.poligono_novo_list.length> 0){
      var poligono_novo_list = this.state.poligono_novo_list;
      var novo_id = poligono_novo_list[poligono_novo_list.length-1].id;
      var poligono = 
          {id: novo_id+1
            ,flag: this.state.poligono_novo_list[0].flag
            , cor: this.state.poligono_novo_list[0].cor 
            ,latitude: lat, longitude: lng, lat_lng: String(lat+", "+lng) };
      poligono_novo_list.push(poligono);
      this.setState({poligono_novo_list: poligono_novo_list});

      // --polygon----
      var poligono_novo_latlngs = this.state.poligono_novo_latlngs;
      if (this.state.poligono_novo_latlngs===null){
        let lat_lng_list = [];
        lat_lng_list.push({lat: lat, lng: lng});

        poligono_novo_latlngs = 
              {id: novo_id+1 
                , cor: this.state.poligono_novo_latlngs.cor       
                , latitude: lat, longitude:lng
                , lat_lng_list: lat_lng_list};  
        }else
            poligono_novo_latlngs.lat_lng_list.push({lat: lat, lng: lng});   

        this.setState({poligono_novo_latlngs: poligono_novo_latlngs, carregarPolygonNovo:false});
        this.setState({carregarPolygonNovo:true});
    }
    //-------------}Polygon-----------------

  }
//-----------------
  onMapClicked = (props) => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null,
          eventoClicado_list: []
        })
      }        

      //-------------Polygon{-----------------
      // console.log("props");
      // console.log(props);


    }; 
  //--------------
  onInfoWindowClose = (props) =>{
    this.setState({eventoClicado_list: [], showingInfoWindow:false, showInfoWindowPosicao:false, showingInfoWindowTrecho:false })
  }
  //-------------
  openBottomSheet(open){
    this.setState({ open });

    if(open==true)
      this.setState({ arrow_collapse: require("./img/arrow_collapse_down.png")});
    else this.setState({ arrow_collapse: require("./img/arrow_collapse_up.png")});
	};

	toggleBottomSheet(){
		this.openBottomSheet(!this.state.open);
  };  
  //-----------------
  onLoadMarker = () => {
    return(
      <div>
        <img src="img/ic_marker_green3.png" alt=""/>
      </div>
    );
  };
  //-----------------
  onLoadEventMarker = () => {
    return(
      <div>
        <Spinner animation="border" />
      </div>
    );
  };
  //-----------------
  onLinkExpirado = () => {
    return(
      <div >
        <Image
          className={ this.state.largura <= 500 ? "center9" : "center8"}
          roundedCircle
          src={require('./img/link_expirado.png')} />
      </div>
    );
  };
  //-------------------
  splashScreenModalClose = async() =>{
    // await this.sleep(1000);
    this.setState({splashScreenModalShow:false});
    // this.setState({abrirModal:false});
   };
  //--------------------
  carregarChamada = async(tipo_chamada, veiculo_id) => {
    if(veiculo_id !== null)
      if(tipo_chamada === TIPO_CHAMADA_MARKERCLICK_VEICULO){
        this.carregarMarkerClick(veiculo_id);
      }else if(tipo_chamada === TIPO_CHAMADA_PLUSCLICK){
        this.carregarMarkerPlusClick();
      }
  }; 
 //---------------------------------------------------
 setTemaMapa = async(tema_mapa) => {
  try {
    let body = new FormData()
    body.append('usuario_id', this.props.usuario_id)
    body.append('mapa_id', tema_mapa)
    body.append('token', localStorage.getItem('token'))

    const response = await api.post('/login_api/salvarTemaMapa', body);
  }catch (response){}

  this.setState({tema_mapa: tema_mapa
                ,map_visible: false});
  await this.sleep(100);
  this.setState({map_visible: true});
};
  //-----------------------------------------------------
  simulateClick(e) {
    // e.click()
  }
  //-----------------------------------------------------
  centerMoved=(mapProps, map)=> {
    // this.callToast(String(mapProps.center.latitude));
    // this.callToast(String(mapProps.center.lat+", "+mapProps.center.lng));
    // this.callToast(String(map.center.lat+", "+map.center.lng));
    // this.callToast(String(this.props.google.maps.LatLng()));
    // console.log(map);
  }
  //---------------------------------------
  onClickVeiculoInformacao = ()=>{
    this.state.Modals.showVeiculoInfoModal(this.state.veiculoPosicao_selecionado);
    // console.log("veiculoPosicao_selecionado: ");
    // console.log(this.state.veiculoPosicao_selecionado);
  }
  //--------------------------------------------------------------
  postModoMapa = async (usuario_id, modoMapa) => {  
    try {
        let body = new FormData();
        body.append('modo_mapa', modoMapa);
        body.append('usuario_id', usuario_id);
        body.append('token', localStorage.getItem('token'));

        const response = await api.post('/login_api/setModoMapa', body);            
        var responseJson = null
        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);      
            
            // this.props.MapaKseg.toast("Atualizado GrupoVeiculoAtual!");
        }

    } finally {

        }
  };
  //--------------------------------------------------------------
  postMapaAtivo = async (usuario_id, mapa_ativo) => {  
    try {
        let body = new FormData();
        body.append('mapa_ativo', mapa_ativo);
        body.append('usuario_id', usuario_id);
        body.append('token', localStorage.getItem('token'));
        const response = await api.post('/login_api/setMapaAtivo', body);            
        var responseJson = null
        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);                  
            // this.props.MapaKseg.toast("Atualizado GrupoVeiculoAtual!");
        }
    } finally {}
  };
  //-----------------------------------------------------
  onMapaModoClick = () => {
    if(this.state.modoMapa===false){
      this.postModoMapa(this.props.usuario_id, true);
      this.postMapaAtivo(this.props.usuario_id, true);
    }
    
    this.setState({buttonMapaModo: "primary", buttonGridModo: "outline-primary", modoMapa:true, mapa_ativo: true, segundos:120, splitPaneSize:0, SplitPaneAllowResize:false});
    // this.setState({buttonMapaModo: "primary", buttonGridModo: "outline-primary", modoMapa:true, mapa_ativo: true, segundos:30, splitPaneSize:0, SplitPaneAllowResize:false});
    localStorage.setItem('modoMapa', true);
    
  };
  //-----------------------------------------------------
  onGridModoClick = () => {

    if(this.state.modoMapa) this.postModoMapa(this.props.usuario_id, false);

    this.setState({buttonMapaModo: "outline-primary", buttonGridModo: "primary", modoMapa:false, splitPaneSize:500, SplitPaneAllowResize:true});
    this.setState({bsOverflowHeight: 0, open:false});  // Se o bottomsheet estiver aberto ou expandido
    localStorage.setItem('modoMapa', false);
  };
  //--------------------------------------------------------------
  onSplitChanged({ size }) {
    this.toast(size);
  }
  //-----------------------------------------------------
  render(){

    const BottomSheetStyles={
			title:{
				backgroundColor: this.state.bsColor,
				padding: '16px 0',
				boxSizing: 'border-box',
				color: 'white',
				minHeight: '64px',
				fontSize: '24px',
        textAlign: 'center',
        flex:1
			},
			colouredDiv: hue => ({
				height: '100px',
				backgroundColor: `hsl(${hue%360}, 80%, 80%)`
			})
    };
    //--
    var triangleCoords = [
        {lat: 25.774, lng: -80.190},
        {lat: 18.466, lng: -66.118},
        {lat: 32.321, lng: -64.757},
        {lat: 25.774, lng: -80.190}
      ];

      let obj = []
      obj.push({
        title: 'modify',
        handler: () => {
          window.alert('modify clicked!')
        }
      })
      obj.push({
        title: 'delete',
        handler: () => {
          window.alert('delete clicked!')
        }
      })

      var mapStyle = [
        { "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" } ] },
        { "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }];

        // var ReactCountdownClock = require('react-countdown-clock');
      //-----------------
      
      // var zoomControlOp = new this.props.google.maps.zoomControl();

      // var bounds = new this.props.google.maps.LatLngBounds();
      // bounds.extend({ lat: 42.02, lng: -77.01 });

      // const google= MapProps;
      const google = this.props.google;
      // var mmarker=String('./img/ic_marker_green3.png');
      var mmarker = require('./img/ic_marker_green3.png');
      var x_text = "10";
      var placa_len="";
      var width_placa="58";
      var data_receb_evento= "";
      var marcador=""; 
      var marker;

      var marcador_trecho="";
      var marker_trecho="";

      const containerStyle = {
        position: 'relative', 
        width: '100%',
        height: '50%',
        
      }

      return(
        <div>
          
            <div>
          { this.state.loading ?
            <Spinner animation="border" />
          :          
          this.state.list.length == 0 ?
            this.onLinkExpirado()
            :
            this.state.mapa_ativo? 
            <Map google={this.props.google}
              // containerStyle={containerStyle}
              styles={mapStyles.getMapaStyle(this.state.tema_mapa)}
              // visible={this.state.map_visible}
              zoom={this.state.zooom}
              onClick={this.mapClicked}
              zoomControl={true}
              center={this.state.center}
              onDragend={this.centerMoved} 
              // onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
            //   zoomControlOptions= {
            //     zoomControlOp
            // }
              // bounds={bounds}
              mapTypeControl={true}
              initialCenter={{
                lat: -27.092218,
                lng: -52.612825
              }}

              // onReady={(mapProps, map) => this.getPosicoesList()}
              >

              {this.state.trecho_posicao_list.length>0 ?
                  <Polyline
                    path={this.state.trecho_posicao_coords}
                    strokeColor={this.state.polylineColor_list[this.state.indexPolylineColor]}
                    strokeOpacity={1.8}
                    strokeWeight={5} />

                // <TrechoPosicao  list={this.state.trecho_posicao_list} />
                : ""
              }

              {this.state.trecho_posicao_list.length>0 ?
                this.state.setas_visible?
                  this.state.trecho_posicao_list.map(posVeiculo => (
                    <Marker 
                      key={posVeiculo.recebimento_id}
                      // title={posVeiculo.recebimento_id}
                      // title={"  lat: "+String(posVeiculo.latitude)+ "  lng: "+String(posVeiculo.longitude)}
                      title={String(posVeiculo.data_receb)}
                      // title={"  lat: "+String(posVeiculo.latitude)+ "  lng: "+String(posVeiculo.longitude)+ "\n    "+String(posVeiculo.data_receb)}
                      // name={posVeiculo.direcao}
                      name={posVeiculo.recebimento_id}
                      position={{lat: posVeiculo.latitude, lng: posVeiculo.longitude}}
                      // onClick={this.toastss(posVeiculo.direcao)}
                      onClick={this.onMarkerPosicaoTrechoClick}

                      {...posVeiculo.direcao===0 ? marcador_trecho=require('./img/point_position.png') : marcador_trecho=require('./img/point_position4_3.png')}
                      {...posVeiculo.backup ? marcador_trecho=require('./img/point_backup_arrow.png') : ""}
                      // {...posVeiculo.tipo_recebimento_id===6 ? marcador=require('./img/point_position_red_arrow.png') : ""}
                      {...posVeiculo.tipo_evento_id===1 ? 
                          posVeiculo.direcao===0 ?
                            marcador_trecho=require('./img/point_position_red2.png') 
                          : marcador_trecho=require('./img/point_position_red_arrow.png') 
                        : ""}

                        
                      {...posVeiculo.tipo_evento_id===68 ? 
                        posVeiculo.direcao===0 ?
                        marcador_trecho=require('./img/point_position_yellow.png') 
                        : marcador_trecho=require('./img/point_position_yellow_arrow.png') 
                      : ""}  

                      {...marker_trecho=
                        'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" '
                      +' height="20px" width="20px" > ' 
                      +' <g transform="rotate('+posVeiculo.direcao+' 10 10)"  >'
                      +'  <image xlink:href="'+marcador_trecho+'"  />   '  
                      +' </g >'
                      +''                                   
                      +''
                      + '   </svg> '
                    }
                    
                    icon={{url: marker_trecho
                        ,anchor: new this.props.google.maps.Point(10,10)}}
                      
                      />
                    ))
                  // <TrechoPosicao  list={this.state.trecho_posicao_list} />
                  : ""
                : ""  
              }


                {this.state.trecho_evento_list.length>0 ?
                    this.state.eventos_visible ?
                      this.state.trecho_evento_list.map((evento, index) =>
                          evento.tipo_evento_id ===68 || evento.tipo_evento_id ===69 || evento.tipo_evento_id ===70? 
                              <Marker 
                                key={evento.recebimento_id}
                                title={String(evento.data_format)}
                                name={evento.recebimento_id}
                                position={{lat: evento.latitude, lng: evento.longitude}}
                                onClick={this.onMarkerEventoTrechoClick}
                                icon={evento.icone_marker}                 
                              />                      
                          : ""                                  
                      )
                      :""
                  : ""
              }




              {/* {this.state.carregarPolyline ? 
                this.state.polyline_list.map(polyline =>   
                    polyline.esperando_renderizar?
                      polyline.polyline :""
                  )
                :""     
              } */}

              {/* {this.state.carregarPolyline ? this.renderPolyline1(): ""}  */}
              {this.state.carregarPolyline ? this.renderPolyline(): ""} 
              {this.state.carregarPolyline ? this.renderPolylinee(): ""} 
              {/* {this.state.carregarPolyline ? <PolylineRender coords={this.state.coords} MapaKseg={this}/>: ""} */}
              {/* {this.state.carregarPolyline ? < PolylineRender2 coords={this.state.coords} color={this.state.polylineColor_list[this.state.indexPolylineColor]} />: ""} */}
              {this.state.carregarPolygon ? this.renderPolygon(): ""}
              {this.state.carregarPolygon ? this.renderPolygonClientes(): ""}
              {this.state.carregarPolygonNovo ? this.renderPolygonNovo(): ""}
              {this.state.carregarPolygonNovo ? this.renderMarkerPolygonNovo(): ""}

              {/* {this.state.carregarPolyline ? this.renderPolyline2() : ""} */}
              {/* {this.state.carregarPolyline ? this.renderPolyline3() : ""} */}

              {/* {this.state.carregarFlechas ? this.renderFlechas(): ""} */}
              {/* {this.state.carregarFlechas ? this.renderFlechas(): ()=>this.cleanMarkerPosicao()} */}



              {this.state.setas_visible?
                this.state.markerPosicao_list.map(posVeiculo =>   ( 
                    <Marker 
                      key={posVeiculo.recebimento_id}
                      // title={posVeiculo.recebimento_id}
                      // title={"  lat: "+String(posVeiculo.latitude)+ "  lng: "+String(posVeiculo.longitude)}
                      title={String(posVeiculo.data_receb)}
                      // title={"  lat: "+String(posVeiculo.latitude)+ "  lng: "+String(posVeiculo.longitude)+ "\n    "+String(posVeiculo.data_receb)}
                      // name={posVeiculo.direcao}
                      name={posVeiculo.recebimento_id}
                      position={{lat: posVeiculo.latitude, lng: posVeiculo.longitude}}
                      // onClick={this.toastss(posVeiculo.direcao)}
                      onClick={this.onMarkerPosicaoClick}

                      {...posVeiculo.direcao===0 ? marcador=require('./img/point_position.png') : marcador=require('./img/point_position4_3.png')}
                      {...posVeiculo.backup ? marcador=require('./img/point_backup_arrow.png') : ""}
                      // {...posVeiculo.tipo_recebimento_id===6 ? marcador=require('./img/point_position_red_arrow.png') : ""}
                      {...posVeiculo.tipo_evento_id===1 ? 
                          posVeiculo.direcao===0 ?
                            marcador=require('./img/point_position_red2.png') 
                          : marcador=require('./img/point_position_red_arrow.png') 
                        : ""}

                        
                      {...posVeiculo.tipo_evento_id===68 ? 
                        posVeiculo.direcao===0 ?
                          marcador=require('./img/point_position_yellow.png') 
                        : marcador=require('./img/point_position_yellow_arrow.png') 
                      : ""}  

                      {...marker=
                        'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" '
                      +' height="20px" width="20px" > ' 
                      +' <g transform="rotate('+posVeiculo.direcao+' 10 10)"  >'
                      +'  <image xlink:href="'+marcador+'"  />   '  
                      +' </g >'
                      +''                                   
                      +''
                      + '   </svg> '
                    }
                    
                    icon={{url: marker
                        ,anchor: new this.props.google.maps.Point(10,10)}}
                      
                      />
                    ))
                  : "" }

              {this.state.loadReadyList?
                this.state.list.map((posicao, index) =>   
                 
                  // this.state.list.length -1 == index?
                  //   alert("list.length -1 == index:  "+this.state.list.length)
                  // :
                  <Marker 
                    key={posicao.veiculo_id}
                    title={String(posicao.data_receb)}
                    // title={"  lat: "+String(posicao.latitude)+ "  lng: "+String(posicao.longitude)+ "\n    "+String(posicao.data_receb)}
                    name={posicao.veiculo_id}
                    // onMouseover={this.onMouseoverMarker}
                    // description={marker.get('description') }
                    // properties={marker.get('properties') }
                    position={{lat: posicao.latitude, lng: posicao.longitude}}
                    onClick={this.onMarkerClick}              
                    
                    {...posicao.velocidade>0 ?  mmarker=require('./img/ic_marker_green3.png') : mmarker= require('./img/ic_marker_red6.png')}

                    {...posicao.tipo_evento_id===3 ?  mmarker=require('./img/ic_marker_bloqueio.png') : ""}                

                    {...posicao.diff_minutes>=60? 
                        posicao.tipo_evento_id===3 ?
                          mmarker=require('./img/ic_marker_bloqueio_sem_sinal.png')
                        :mmarker=require('./img/ic_marker_grey2.png')
                      : ""}
                    {...placa_len=posicao.placa.length}

                    {...placa_len<8 ? 
                      x_text="14"                
                      : posicao.placa.includes("-") ? x_text="6"  : x_text="13" }

                    icon={{
                      url: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" '
                      +' height="50px" width="70px" > <g > <image xlink:href="'+mmarker+'" x="10" y="5" /> '
                      +' <rect rx="4" ry="4" x="5" width="'+width_placa+'" height="12" style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(0,0,0)" /> '
                      +' <text x="'+x_text+'" y="10" style="font-family:verdana;" font-size="10" font-weight="bold"> '
                      +'    <tspan >'+posicao.placa+'</tspan> '
                      +' </text> </g> '
                      + '   </svg> '                 
                      }}

                    // icon={{url: require('./img/point_position.png')
                    // icon={{url: require('./img/mapmarkerdown.svg')
                    // icon={{url: require('./img/mapmarkerdown.xml')
                    // icon={{url: require('./img/point_position.png')
                    //   ,anchor: new this.props.google.maps.Point(10,10)}}
                      
                    />
                    ) 
                : ""
              }

    
              
              {this.state.markerPlus_visible?               
              <Marker 
                  key={this.state.markerPlus.veiculo_id}
                  title={"Carregar mais"}
                  name={this.state.markerPlus.veiculo_id}
                  position={{lat: this.state.markerPlus.latitude, lng: this.state.markerPlus.longitude}}
                  onClick={this.onMarkerPlusClick}
                  icon={{url: require("./img/marker_pluscircle_3.png"), anchor: new this.props.google.maps.Point(60,30)}}
                  />  
              :""}  
                  
              {this.state.eventos_visible ?
                this.state.eventos_list.map((evento, index) =>
                  evento.visible_marker ?
                    evento.tipo_evento_id ===68 || evento.tipo_evento_id ===69 || evento.tipo_evento_id ===70 || evento.tipo_evento_id ===74? 
                        <Marker 
                          key={evento.recebimento_id}
                          title={String(evento.data_inicio2)}
                          // name={evento.data_receb}
                          name={evento.recebimento_id}
                          position={{lat: evento.latitude, lng: evento.longitude}}
                          // title={"  lat: "+String(evento.latitude)+ "  lng: "+String(evento.longitude)}
                          onClick={this.onMarkerEventoClick}

                        // {...evento.tipo_evento_id != 66 ? 
                        //   icon={require("./img/point_position_red2.png")}
                        // : ""}

                          icon={                    
                            evento.icone_marker
                          } 
                        
                          // icon={{url: evento.icone_marker
                          //     ,anchor: new this.props.google.maps.Point(10,10)}}                   
                        />                      
                    : ""  
                  : ""                                                        
                )
              :""}


        



              {/* { this.state.markerEvento_list.map((evento, index) =>
                evento.maark
              )} */}

              <InfoWindow
                  // marker={this.state.marker_aux}
                  position = {this.state.infoWindowLocation} 
                  // visible={true}
                  visible={this.state.showingInfoWindow}              
                  onClose={this.onInfoWindowClose}
                  >
                    {/* { this.state.eventos_list.map((evento, index) =>  */}
                    { this.state.infoWindowEvento ?
                        this.state.eventos_visible ?
                          this.state.eventos_list.map((evento, index) =>               
                            this.state.selectedPlace === evento.recebimento_id ?
                              <EventoCard value={evento} MapaKseg={this} showMapIcon={false}/>
                            : "" 
                          )
                        : ""  
                      : 
                        this.state.markerPosicao_list.map((evento, index) =>               
                          this.state.selectedPlace === evento.recebimento_id ?
                            <EventoCard value={evento} MapaKseg={this} showMapIcon={false}/>
                          : ""  
                        )
                    }

                </InfoWindow>

                <InfoWindow
                  // marker={this.state.marker_aux}
                  position = {this.state.infoWindowLocation} 
                  // visible={true}
                  visible={this.state.showInfoWindowPosicao}              
                  onClose={this.onInfoWindowClose}
                  >
                  <EventoCard value={this.state.posicaoInfoWindow} MapaKseg={this} showMapIcon={false}/>
                </InfoWindow>

                


                <InfoWindow
                  position = {this.state.infoWindowLocation} 
                  visible={this.state.showingInfoWindowTrecho}              
                  onClose={this.onInfoWindowClose}
                  >
                    {this.state.posicaoInfoWindowList.map((evento, index) =>               
                      <EventoCard value={evento} MapaKseg={this} showMapIcon={false}/>
                    )}
                  
                </InfoWindow>

                {/* <InfoWindow
                  // marker={this.state.marker_aux}
                  position = {this.state.infoWindowLocation} 
                  // visible={true}
                  visible={this.state.showingInfoWindowTrecho}              
                  onClose={this.onInfoWindowClose}
                  >
                  <EventoCard value={this.state.posicaoInfoWindow} MapaKseg={this} showMapIcon={false}/>
                </InfoWindow> */}


    {/* 
                <InfoWindow
                  // marker={this.state.marker_aux}
                  position = {this.state.infoWindowLocation} 
                  // visible={true}
                  visible={this.state.showingInfoWindowTrecho}              
                  onClose={this.onInfoWindowClose}
                  >
                    { this.state.infoWindowEvento ?
                        this.state.trecho_evento_list.length>0 ?
                          this.state.eventos_list.map((evento, index) =>               
                            this.state.selectedPlace === evento.recebimento_id ?
                              <EventoCard value={evento} MapaKseg={this} showMapIcon={false}/>
                            : this.callToast("Nao achou posicao") 
                          )
                          :""
                      : 
                        this.state.trecho_posicao_list.map((evento, index) =>               
                          this.state.selectedPlace === evento.recebimento_id ?
                            <EventoCard value={evento} MapaKseg={this} showMapIcon={false}/>
                          : this.callToast("Nao achou evento")   
                        )
                    }

                </InfoWindow> */}


                          
            
            {this.state.modoMapa ?
              <SwipeableBottomSheet 
                  overflowHeight={this.state.bsOverflowHeight}
                  marginTop={100}
                  open={this.state.open}
                  onChange={this.openBottomSheet.bind(this)}
                >
                  
                  <nav className={"navbar navbar-expand-md navbar-dark "+this.state.backgroundBottomSheet}>
                    {this.state.veiculoPosicao_selecionado !== null ?
                    this.state.veiculoPosicao_selecionado !== undefined ?
                      <table className="bottomsheet_infos">
                        <tr>

                          <td className="bold">  <Form.Check              
                                                  label="Setas"
                                                  name="carregarSetas"
                                                  title="Carregar Setas de posição"
                                                  id={1}
                                                  checked={this.state.setas_visible}
                                                  onChange={()=>this.setasVisible()}
                                              /></td>

                            {this.state.pessoa_id===1?
                              <td >    
                              <button className="button4"  onClick={()=> this.onClickVeiculoInformacao()}>         
                                <Image alt="" title={"Informações"} roundedCircle 
                                  src={require('./img/car_info36.png')} width="40"/>
                                  </button> 
                              </td>
                            :""}
                          
                            <td >  <div className="placa">{this.state.veiculoPosicao_selecionado.placa}</div></td>
                            <td className="bottomsheet_info">                      
                              <text title={this.state.veiculoPosicao_selecionado.data_receb} >{this.state.veiculoPosicao_selecionado.hora}</text>  
                            </td>
                            <td className="municipio">                      
                              <text>{this.state.veiculoPosicao_selecionado.municipio}  </text>           
                              <br></br>
                              <text> {this.state.veiculoPosicao_selecionado.estado}</text>
                            </td>
                            <td >              
                              <img alt="" title={this.state.veiculoPosicao_selecionado.territorio}
                                src={
                                  this.state.veiculoPosicao_selecionado.pais !== undefined ?
                                  require('./img/'+this.state.veiculoPosicao_selecionado.pais+'.png')
                                :"" } />
                            </td>
                        </tr>
                  
                    
                      </table>
                      :""
                  : "" }

                    <div className="botoes_window">
                      <button className="button2"  onClick={()=> this.toggleBottomSheet()}>
                        <img src={this.state.arrow_collapse} width="20" />
                      </button>
                      <button className="button2"  onClick={()=> this.closeBottomSheet()}>
                        <img src={require("./img/close.png")} width="20" />
                      </button>
                    </div>
                    <br></br>
                    <br></br>


                  
                  </nav>
                
                
                  {/* <paginationSwipe />   */}

                
                  <nav className={"navbar navbar-expand-md navbar-dark "+this.state.backgroundBottomSheet2}>     
                    <div id="outer">                  

                        {this.state.veiculoPosicao_selecionado !== null ?
                              this.state.veiculoPosicao_selecionado.temperaturas_tab ?       
                                <Button variant={this.state.tabTemperaturaSel? this.state.light : this.state.outlineLight}
                                    onClick={() => this.buttonTemperaturaClick()} >TEMPERATURA</Button>
                                  
                                :""
                              :""  
                            }         
                    
                        <text>    </text> 
                        <Button variant={this.state.tabHistoricoSel? this.state.light : this.state.outlineLight}
                                onClick={() => this.buttonHistoricoClick()} >HISTÓRICO</Button>
                        <text>    </text>                    
                      
                    </div>
                                  
                  </nav>
                    
                  <div id="outer">
                    <div className="inner">
                      <Carousel interval={null}  controls={false} indicators={false} activeIndex={this.state.activeIndexCarousel}>
                        <Carousel.Item className="background_cinza3">                                      
                          {this.state.eventos_visible ?
                            this.state.eventos_list.length>0 ? 
                              <RenderEventos mapaKseg={this} /> : ""
                          :""}

                          <div className="espaco_topo_bottom">
                            <Button variant="light" onClick={() => this.onMarkerPlusClick()}>
                                {this.state.carregandoMaisEventos ?
                                    <div><Spinner animation="border" />CARREGANDO...</div>
                                  : <div>CARREGAR MAIS</div>
                                }
                            </Button>
                          </div>
                        </Carousel.Item>            

                        {this.state.veiculoPosicao_selecionado !== null ? 
                          this.state.veiculoPosicao_selecionado.temperaturas_tab ?
                            // this.callToast(String(this.state.veiculoPosicao_selecionado.temperaturas_tab))
                              <Carousel.Item>                 
                                <RenderTemperatura
                                    mapaKseg = {this}
                                    placa={this.state.veiculoPosicao_selecionado.placa}
                                    veiculo_id={this.state.veiculoPosicao_selecionado.veiculo_id}
                                    splashScreenModalShow = {this.state.splashScreenModalShow}
                                    /> 
                              </Carousel.Item>   
                            : ""
                        : ""}

                      </Carousel> 
                    </div>
                  </div>



                  
        


                  {/* {Array(30).fill().map((o, i) => 
                    <div key={i} style={BottomSheetStyles.colouredDiv(i*20)} />
                  )} */}


                </SwipeableBottomSheet>
              : ""}
              


              {this.state.splashScreenModalShow ?
                <div className="center7"> 
                  <Ring  color="#FFF" /> 
                </div>
                :""}

              
              {/* <Ripple  color="#6342d7" />  */}



          </Map> 
        :""}
        
        

        </div>
        
          {this.state.modoMapa ===false?      
            // this.state.list.length >0 ?
            <div className="espaco_esq_gridoperacional">
              <PainelGrid MapaKseg={this}/>
            </div>
            :""
          // :""            
          }
        

      

      {this.state.modoMapa ?
        <div className="height_top">
          <ButtonFab 
            className={this.state.buttonClockTransp}
            tooltip="Clique para recarregar!"
            styles={{backgroundColor: "transparent", color: lightColors.white}}
            // onClick={()=>this.recarregarPosicoes()}
            >
            {/* <div>
              <img src="img/reloadRight.png"/>
            </div>           */}

              
                <div className="padd_dir" >
                  <ReactCountdownClock               
                    key={this.state.completions}
                    seconds={this.state.segundos}
                    showMilliseconds={false}
                    color="#6342d7"
                    size={50}
                    onComplete={()=>this.recarregarPosicoesCountdown()}
                    onClick={()=>this.recarregarPosicoesClick()} /> 
                </div>
          </ButtonFab>       
        </div>
      :""}
      

      {this.state.list.length > 0 ?
        <span className="height_top_center4">
          <img src={require("./img/kseg_logo_escrita.png")}  width="90"/>
        </span>
      : ""}


      {/* {
        this.state.modoMapa ?
          this.state.filter_list.length>0 ? 
            <span className="height_top_center3">              
              <FilterSelect list={this.state.filter_list} MapaKseg={this} tipo={1} />
            </span>
            : ""
        : ""    
      } */}

        

                               
       



        
        <Modals filter_list={this.state.filter_list} usuario_id={this.props.usuario_id} MapaKseg={this} expanded={this.state.sideNavDrawer_expanded} />

        <ToastContainer position={ToastContainer.POSITION.BOTTOM_CENTER} store={ToastStore}/>

      </div>
      );
  }

}

// MapaKseg.defaultProps = mapStyles;

export default GoogleApiWrapper(
  (props) => ({
    // apiKey: "AIzaSyCM9NyJSc6a4OOey7nNCIhMoX3ellj1xm0",     // Essa chave usar off
    apiKey: "AIzaSyADcVq6g9Vr3jpGIVhBK8v4ThVnzxJU0Mw",   // Essa chave usar online
    language: "pt-BR",
  }
))(MapaRastreio);
